.d-f {
  display: flex;
}
.d-b {
  display: block;
}

.jc-sb {
  justify-content: space-between;
}

.jc-c {
  justify-content: center;
}

.jc-e {
  justify-content: flex-end;
}

.ai-c {
  align-items: center;
}
.ai-s {
  align-items: stretch;
}

.ml-1 {
  margin-left: 8px;
}

.w-b {
  word-break: break-all;
}
