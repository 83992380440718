.Documents {
  display: flex;
  flex-wrap: wrap;

  .card-noItems {
    min-height: 480px;
  }

  &Sidebar {
    width: 302px;
    padding-right: 33px;
    margin-bottom: 16px;

    &-search {
      padding: 16px;
      width: 100%;
      height: 72px;
      background: #ffffff;
      margin-bottom: 24px;
    }
  }

  &Container {
    width: calc(100% - 302px);
  }

  .ProductsFilter {
    height: auto;
  }
}

.DocumentItem {
  display: flex;
  background-color: white;
  padding: 8px;
  border-radius: 4px;
  justify-content: space-between;
  margin-bottom: 16px;

  &-left {
    width: calc(100% - 64px);
  }

  &-name {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: var(--cl-secondary);
    margin-bottom: 4px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
  }

  &-size {
    font-size: 12px;
    line-height: 20px;
    color: var(--cl-gray);
    font-family: 'Inter', sans-serif;
  }

  &-wrapper {
    position: relative;
  }

  &-download {
    position: absolute !important;
    top: 8px !important;
    right: 8px !important;
    min-width: 48px !important;
    width: 48px !important;
    z-index: 2 !important;

    i {
      margin-left: 0 !important;
    }
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      .DocumentItem-name {
        color: var(--cl-primary) !important;
      }
    }
  }
}

.DocumentsContainer-noItems {
  width: 100%;
  text-align: center;
  padding-top: 80px !important;
}

@media screen and (max-width: 860px) {
  .Documents {
    &Sidebar {
      display: none;
    }

    &Container {
      width: 100%;
    }

    .card-noItems {
      padding-top: 64px;
    }
  }

  .DocumentItem {
    margin-bottom: 8px;
  }

  .DocumentsContainer-noItems {
    button {
      width: auto !important;
    }
  }

  .ProductsHeader {
    margin: 0 -16px;
    padding: 16px;
  }
}
