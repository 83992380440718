.AdminOrderDetail {
  &-block {
    padding-bottom: 20px;

    &:last-child {
      padding-bottom: 0;
    }
  }

  &-blockTitle {
    font-size: 16px;
    font-weight: bold;
    font-family: 'Inter', sans-serif;
    margin-bottom: 8px;
  }

  &-blockContent {
    font-size: 16px;
    margin-left: 24px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    margin-bottom: 4px;
  }

  &-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    justify-content: space-between;

    .left {
      width: calc(100% - 560px);
    }

    .right {
      width: 540px;
    }

    @media screen and (max-width: 1200px) {
      .left {
        width: calc(100% - 420px);
      }

      .right {
        width: 400px;
      }
    }

    @media screen and (max-width: 860px) {
      .left {
        width: 100%;
      }

      .right {
        margin-top: 20px;
        width: 100%;
      }
    }
  }

  &-discountCode {
    padding: 4px;
    font-size: 15px;
    word-break: break-word;

    & > div {
      font-family: 'Inter', sans-serif;
    }
  }

  &-changeStatus {
    margin-top: 24px;
    margin-left: 4px;
    font-family: 'Inter', sans-serif;
    font-size: 15px;
  }

  &-cancel {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    padding: 20px 0;

    div {
      font-family: 'Inter', sans-serif;
      font-size: 15px;
    }
  }
}

.ChangeOrderStatus {
  font-family: 'Inter', sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px 0px 16px;
  height: 30px;
  white-space: nowrap;
  overflow: hidden;
  border-radius: 20px;
  font-weight: 400;
  font-size: 13.5px;

  i {
    font-size: 24px;
  }
}

.ChangeOrderStatus-item {
  font-family: 'Inter', sans-serif;
  height: 36px;
  font-size: 13.5px !important;
  font-weight: 400;
  padding: 0 12px !important;

  i {
    font-size: 14px;
    margin-right: 8px;
    color: var(--cl-gray);
  }
}

.step-icon {
  font-size: 30px;
}

.MuiStepLabel-label {
  font-weight: 500;
}

.MuiStepLabel-label.MuiStepLabel-alternativeLabel {
  &.Mui-completed,
  &.Mui-active {
    color: var(--cl-admin-success);
  }
}

.MuiStepConnector-line.MuiStepConnector-lineHorizontal {
  width: 90%;
  margin: 5px auto;
}

.AdminOrderStatus {
  display: flex;
  align-items: center;
  padding: 0px 18px 2px;
  height: 35px;
  font-family: 'Inter', sans-serif;
  min-width: 160px;
  justify-content: center;
  font-size: 15px;
  font-weight: 500;
  border-radius: 20px;
  box-shadow: 0 5px 8px rgba(153, 153, 153, 0.3), 0 8px 16px rgba(153, 153, 153, 0.15);
}

.ModalUpdateOrder {
  &-field {
    margin-bottom: 12px;
  }

  &-title {
    min-width: 180px;
    width: 180px;
    margin-right: 12px;
  }
}

.AdminAddOrder {
  .InputSearch .MuiOutlinedInput-root {
    max-width: 100% !important;
  }
  .MuiCard-root {
    overflow: visible;
  }
  &-wrapper {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
  }
  .left {
    width: 40%;
    margin-right: 30px;
  }
  .right {
    width: 60%;
  }
  @media screen and (max-width: 1500px) {
    &-wrapper {
      flex-wrap: wrap;
    }
    .left {
      width: 100%;
      margin-right: 0;
      .block {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 30px;
      }
    }

    .right {
      margin-top: 20px;
      width: 100%;
    }
  }
  &-userInfor {
    &-header {
      margin-bottom: 20px;
      .title {
        font-size: 16px;
        font-weight: 600;
      }
      .select {
        width: 280px;
      }
    }
  }
  &-delivery {
    &-header {
      margin-bottom: 20px;
      .title {
        font-size: 16px;
        font-weight: 600;
      }
      .select {
        width: 280px;
      }
      .method {
        width: 228px;
        height: 36px;
        display: flex;
        align-items: center;
        padding: 8.5px 14px;
        border: 1px solid var(--cl-gray);
        border-radius: 6px;
      }
    }
  }

  &-btnWrapper {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &-btnCancel {
    width: 250px;
    margin-right: 20px;
  }

  &-btnAdd {
    width: 250px;
  }
}
