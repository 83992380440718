.TabsItem {
  &-wrapper {
    width: 100%;
    background: #ffffff;
    border-radius: 4px;
    height: 40px;
    padding-left: 8px;
    padding-right: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin-bottom: 4px;

    &.disabled {
      cursor: default;
    }

    &.open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      margin-bottom: 0px;
      border-bottom: 1px solid var(--cl-light);
    }

    &.isPlus {
      //   padding-inline: 15px;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 0px;
      border-radius: 0;
      i {
        font-size: 16px;
        margin-right: 8px;
      }
    }

    i {
      color: var(--cl-gray);
    }

    &__label {
      color: var(--cl-secondary);
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
    }

    @media (hover: hover) and (pointer: fine) {
      &.active:not(.disabled),
      &:hover:not(.disabled) {
        &.isPlus {
          i {
            color: var(--cl-primary);
          }
        }

        .TabsItem-wrapper__label {
          color: var(--cl-primary);
        }
      }
    }
  }
}
