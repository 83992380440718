.ImageViewer {
  &-container {
    background: rgba(3, 3, 3, 0.3) !important;
    & .ril {
      &__builtinButton {
        width: 24px;
        height: 24px;
        cursor: pointer;
        border: none;
        opacity: 0.7;
        position: absolute;
        right: 16px;
        top: 16px;
      }
      &-image {
        // &-current,
        img {
          max-width: 750px;
          max-height: 750px;
          // min-width: 200px;
          // min-height: 200px;
          width: 750px;
          height: 750px;
          object-fit: contain;
          background: #fff;
          @media screen and (max-width: 990px) {
            max-width: 500px;
            max-height: 500px;
          }
          @media screen and (max-width: 640px) {
            max-width: 400px;
            max-height: 400px;
          }
          @media screen and (max-width: 480px) {
            max-width: 300px;
            max-height: 300px;
          }
        }
      }
      &__toolbar {
        background: transparent;
      }
      &__navButtons {
        padding: 0;
        width: 48px;
        height: 48px;
        background-color: #fff;
        font-size: 20px;
        color: #848687;
        border-radius: 50%;
        background-size: 20%;
        box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.2);
        display: inline-flex;
        justify-content: center;
        align-items: center;
        &:before {
          font-family: 'mn-icons' !important;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          content: '\e902';
        }
      }
      &__navButtonPrev {
        left: calc(50% - 498px);
        &::before {
          transform: rotate(180deg);
        }
        @media screen and (max-width: 990px) {
          left: 16px;
        }
      }
      &__navButtonNext {
        right: calc(50% - 498px);
        @media screen and (max-width: 990px) {
          right: 16px;
        }
      }
    }
  }
}

.ReactModal__Overlay {
  @media screen and (max-width: 860px) {
    background-color: var(--cl-secondary) !important;
  }
}
