.RelativeProducts {
  &-area {
    margin-top: 32px;
    @media screen and (max-width: 860px) {
      margin-top: 0;
    }
  }
  &-title {
    color: var(--cl-secondary);
    font-size: 24px;
    line-height: 29px;
    font-weight: bold;
    @media screen and (max-width: 860px) {
      display: none;
    }
  }
  &-desc {
    margin: 24px 0;
    color: var(--cl-gray);
    line-height: 20px;
  }
  &-list {
    display: flex;
    flex-wrap: wrap;
    & .ProductItemNew {
      &-container {
        margin: 0 30px 30px 0;
        width: calc((100% - 90px) / 4);
        &:nth-child(4n + 4) {
          margin-right: 0;
        }
        @media screen and (max-width: 990px) {
          margin: 0 24px 24px 0;
          width: calc((100% - 48px) / 3);
          &:nth-child(4n + 4) {
            margin-right: 24px;
          }
          &:nth-child(3n + 3) {
            margin-right: 0;
          }
        }
        @media screen and (max-width: 640px) {
          margin: 0 16px 16px 0;
          width: calc((100% - 16px) / 2);
          &:nth-child(3n + 3) {
            margin-right: 16px;
          }
          &:nth-child(even) {
            margin-right: 0;
          }
        }
      }
      &-img {
        @media screen and (max-width: 860px) {
          height: 220px;
        }
        @media screen and (max-width: 480px) {
          height: 160px;
        }
      }
    }
  }
  &-pagination {
    margin-bottom: 28px;
    display: flex;
    justify-content: flex-end;
    @media screen and (max-width: 640px) {
      margin-bottom: 16px;
      justify-content: center;
    }
  }
  &-noResults {
    margin: 24px 0;
    min-height: 240px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    & > p {
      padding: 0 16px;
      color: var(--cl-gray);
      text-align: center;
    }
  }
}
