.AddressItem {
  max-width: 343px;
  margin-top: 12px;
  border: 1px solid var(--cl-light-gray);
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px 16px 16px 0;
  margin-right: 16px;
  background-color: #fff;

  &.marginBig {
    margin-top: 16px;
  }

  @media screen and (max-width: 860px) {
    max-width: 100%;
    margin-right: 0;
  }

  &-radio {
    margin: 0 8px;
  }

  &-wrapper {
    width: 100%;
  }

  &-type {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    &__type {
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.5px;
      color: var(--cl-secondary);
    }

    &__default {
      padding: 4px 8px;
      background: var(--cl-primary);
      border-radius: 2px;
      font-weight: bold;
      font-size: 8px;
      line-height: 10px;
      text-transform: uppercase;
      color: #ffffff;
      margin-left: 4px;
      display: flex;
      align-items: center;
    }
  }

  &-buttons {
    display: flex;
    margin-top: 8px;

    & > * {
      margin-right: 12px !important;
    }

    button {
      width: 68px !important;
      height: 36px !important;
      font-weight: 600 !important;
      font-size: 10px !important;
      line-height: 12px !important;
      padding: 0 !important;
    }
  }

  .mn-icon-arrow {
    font-size: 16px;
    margin-left: 16px;
    color: var(--cl-gray);
  }
}

.AddressItems-wrapper {
  display: flex;
  flex-wrap: wrap;
}
