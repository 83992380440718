.UserLayout {
  padding-block: 32px;
  @media screen and (max-width: 860px) {
    max-width: 100%;
    padding-block: 0;
  }
  &-title {
    color: var(--cl-secondary);
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 16px;
    @media screen and (max-width: 860px) {
      display: none;
    }
  }
  &-area {
    width: 100%;
    display: flex;
    @media screen and (max-width: 860px) {
      justify-content: center;
    }
  }

  &-component {
    width: calc(100% - 302px);
    @media screen and (max-width: 860px) {
      width: 100%;
    }
  }
}

/*
* Sidebar
*/
.Sidebar {
  max-width: 269px;
  width: 100%;
  margin-right: 33px;
  @media screen and (max-width: 860px) {
    max-width: 100%;
    margin-right: 0;
  }
  &-wrapper {
    list-style: none;
  }
  &-menu {
    list-style: none;
    &--item {
      background: #fff;
      // padding-inline: 15px;
      padding-left: 15px;
      padding-right: 15px;

      &__wrapper {
        border-bottom: 1px solid var(--cl-light);
      }
    }
    &--item:last-child {
      &__wrapper {
        border: 0;
      }
    }
  }
}

/*
* User Information Wrapper
*/
.UserInformationWrapper {
  width: 100%;
  height: 100%;
  padding: 24px;
  background: #fff;
  @media screen and (max-width: 860px) {
    max-width: 100%;
    padding: 0;
    background: transparent;
    overflow-x: auto;
  }
}

.MyOrder {
  &-tabs {
    margin-bottom: 16px;
    @media screen and (max-width: 900px) {
      padding-right: 12px !important;
      padding-left: 12px !important;
      background: #fff;
      .MuiTabs-scroller {
        overflow-x: scroll !important;

        &::-webkit-scrollbar-track {
          display: none;
        }
        &::-webkit-scrollbar {
          display: none;
        }
        &::-webkit-scrollbar-thumb {
          display: none;
        }
      }
    }

    .MuiTabs-indicator {
      background-color: var(--cl-primary);
    }
  }

  &-tabItems {
    padding: 0 16px;
  }
}

.MyOrder-tab.MuiButtonBase-root {
  color: var(--cl-gray);
  font-weight: 600;
  font-size: 12px;
  padding: 0;
  margin-right: 24px;
  border-bottom: 2px solid var(--cl-light-gray);
  min-width: 0;
  text-transform: none;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      color: var(--cl-secondary);
      border-bottom: 2px solid var(--cl-primary);
    }
  }

  &.Mui-selected {
    color: var(--cl-secondary);
  }
}

.MyOrder-box {
  margin-top: 16px;
  @media screen and (max-width: 860px) {
    margin: 16px;
  }
}

.MyOrder-pagination {
  margin-top: 16px;
  .MuiPagination-ul {
    li {
      .MuiButtonBase-root {
        color: var(--cl-gray);
        font-size: 12px;
        font-weight: 400;
      }

      .MuiButtonBase-root.Mui-selected {
        background: var(--cl-primary);
        color: #fff;
      }
    }
  }
  @media screen and (max-width: 860px) {
    display: none;
  }
}

.ProfileMenu {
  padding: 16px 0;
}

.MyOrder {
  &-noneContent {
    margin-top: 60px;
    text-align: center;
  }
  &-imageNonePrd {
    object-fit: cover;
  }
  &-title {
    margin-top: 16px;
    color: #000;
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
  }
  &-paragraph {
    margin: 12px auto 36px;
    max-width: 470px;
    width: 100%;
    color: var(--cl-gray);
    font-size: 14px;
    line-height: 17px;
  }
  &-btnLink {
    max-width: 191px;
    width: 100%;
    margin: 0 auto 104px;
  }
}

.MyOrderDetail {
  &-wrapper {
    padding: 0;
    @media screen and (max-width: 860px) {
      padding: 16px;
    }
  }
  &-headerWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 860px) {
      display: none;
    }
  }
  &-orderName {
    color: var(--cl-secondary);
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
  }
  &-status {
    color: var(--cl-gray);
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
  }

  &-document {
    margin-top: 24px;
    display: flex;
    align-items: center;
    background-color: #f5f7fb;
    padding: 8px 16px;
    border-radius: 4px;
    justify-content: space-between;
    position: relative;
    .document {
      &-name {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: #333333;
        @media screen and (max-width: 600px) {
          margin-bottom: 12px;
        }
      }
      &-view {
        margin-right: 12px;
      }
    }
    @media screen and (max-width: 860px) {
      background-color: #ffffff;
    }
    @media screen and (max-width: 600px) {
      padding: 12px 16px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &-itemWrapper {
    margin-top: 24px;
    padding-bottom: 12px;
    border-bottom: 3px solid var(--cl-light);
    @media screen and (max-width: 600px) {
      margin-top: 12px;
    }
  }
  &-label {
    color: var(--cl-secondary);
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 8px;
    @media screen and (max-width: 860px) {
      margin-bottom: 12px;
    }
  }
  &-MyInformationStatus {
    font-size: 14px;
    line-height: 20px;
    font-family: 'Inter', sans-serif !important;
    color: #333333;
    font-weight: 400;
    div {
      font-family: 'Inter', sans-serif !important;
    }
  }
  &-paymentUI {
    font-size: 14px;
    line-height: 20px;
    font-family: 'Inter', sans-serif !important;
    color: #333333;
    font-weight: 400;
    &__label {
      font-weight: 600;
    }
    div {
      font-family: 'Inter', sans-serif !important;
    }
  }
  &-vatUI {
    font-size: 14px;
    line-height: 20px;
    font-family: 'Inter', sans-serif !important;
    color: #333333;
    font-weight: 400;
    &__label {
      font-size: 16px;
      line-height: 20px;
      font-weight: 700;
      color: var(--cl-secondary);
      margin-bottom: 12px;
    }
    &__default {
      padding: 4px 8px;
      background: var(--cl-primary);
      color: #fff;
      font-size: 8px;
      line-height: 10px;
      font-weight: 700;
      text-transform: uppercase;
      border-radius: 2px;
    }
    div {
      font-family: 'Inter', sans-serif !important;
    }
  }

  &-priceWrapper {
    margin-top: 24px;
    width: 343px;
    margin-left: auto;
    &__btnAccept {
      margin-bottom: 8px;
    }
    &__btnCancel {
      @media screen and (max-width: 860px) {
        padding-left: 16px;
        padding-right: 16px;
      }
    }

    @media screen and (max-width: 860px) {
      background: #fff;
      margin-top: 0;
      width: 100%;
      padding-bottom: 32px;
      &__btnAccept {
        padding-left: 16px;
        padding-right: 16px;
      }
    }
  }
  &-expectedDelivery {
    margin-block: 24px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    color: #333333;
  }
  &-canceledOrder {
    margin-block: 24px;
    background: var(--cl-light);
    padding: 16px;
    @media screen and (max-width: 600px) {
      margin-block: 0;
      padding: 0;
    }
    &__labelWrapper {
      font-size: 14px;
      line-height: 20px;
      font-family: 'Inter', sans-serif;
      color: #333333;
      font-weight: 400;
      margin-bottom: 12px;
    }
    &__label {
      font-weight: 700;
      margin-right: 8px;
    }
    &__contentWrapper {
      font-size: 14px;
      line-height: 20px;
      font-family: 'Inter', sans-serif;
      color: #333333;
      font-weight: 400;
    }
    &__content {
      font-weight: 700;
      margin-right: 8px;
    }
  }
}

.OrderDetailItem {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 8px;

  &-informationWraper {
    display: flex;
    align-items: flex-start;
  }

  &-image {
    margin-right: 8px;
    object-fit: contain;
  }
  &-name {
    color: var(--cl-dark);
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 8px;
    &:hover {
      color: var(--cl-primary);
    }
  }
  &-count {
    color: var(--cl-gray);
    font-size: 10px;
    line-height: 12px;
    font-weight: 400;
    margin-bottom: 8px;
  }
  &-manufacturer {
    color: var(--cl-gray);
    font-size: 10px;
    line-height: 12px;
    font-weight: 400;
  }

  &-oldPrice {
    font-size: 10px;
    line-height: 12px;
    font-weight: 400;
    color: var(--cl-gray);
  }

  &-price {
    font-size: 12px;
    line-height: 15px;
    font-weight: 700;
    color: var(--cl-primary);
  }

  &.border {
    border-bottom: 1px solid var(--cl-light);
  }
}

.MyInformationCollapse {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 12px;
  @media screen and (max-width: 860px) {
    background: #fff;
    padding-block: 12px;
    border-radius: 4px;
  }
  .MuiListItemButton-root {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin-bottom: 12px;
    &:hover {
      background-color: transparent;
    }
  }
  &-label {
    margin-top: 12px;
    color: var(--cl-secondary);
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
  }
  &.border {
    border-bottom: 1px solid var(--cl-light);
  }
}

.MyInformationStatusWFP {
  padding-bottom: 16px;
  border-bottom: 3px solid var(--cl-light);
  @media screen and (max-width: 860px) {
    padding-bottom: 0;
    background: #fff !important;
  }

  &-note {
    padding: 16px;
  }
  &-label {
    margin-bottom: 4px;
  }
}

.MyInformationContent {
  font-family: 'Inter', sans-serif;
  padding: 12px 16px;
  background: var(--cl-light);
  &-content {
    font-family: 'Inter', sans-serif;
  }
}

.PriceInformation {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  margin-bottom: 8px;
  &.border {
    border-top: 1px solid var(--cl-light);
  }
  &.large {
    padding: 12px 16px 15px 16px;
    .PriceInformation-value {
      color: var(--cl-primary);
      font-size: 16px;
      line-height: 20px;
    }
  }
  &-label {
    font-size: 14px;
    line-height: 17px;
    color: var(--cl-gray);
    font-family: 'Inter', sans-serif;
    font-weight: 400;
  }
  &-value {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: var(--cl-secondary);
  }
}
