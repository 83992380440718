.AdminRenderField {
  &-field {
    margin-bottom: 12px;

    &.xl {
      margin-bottom: 16px;

      &.ws {
        font-family: 'Inter';
        font-weight: 500;
      }
    }

    &.center {
      flex-direction: column;
      width: 100%;
      align-items: center;

      .AdminRenderField {
        &-title {
          width: 100%;
          text-align: center;
          margin-bottom: 12px;
          font-size: 17px;
        }
      }
    }

    &.mb-sm {
      margin-bottom: 16px;
    }

    &.mb-md {
      margin-bottom: 20px;
    }
  }

  &-title {
    min-width: 180px;
    width: 180px;
    margin-right: 12px;

    &.sm {
      min-width: 160px;
      width: 160px;
    }

    &.lg {
      min-width: 200px;
      width: 200px;
    }

    &.xs {
      min-width: 110px;
      width: 110px;
    }

    &.xl {
      font-weight: 600;

      &.ws {
        min-width: 150px;
        width: 150px;
      }
    }
  }
}
