@mixin appMenuSublist {
  display: none;
  position: absolute;
  min-width: 234px;
  list-style-type: none;
  padding: 16px 0;
  top: calc(100% - 3px);
  left: 0;
  background: #fff;
  border-top: 3px solid var(--cl-primary);
  border-radius: 0px 0px 8px 8px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);

  @media screen and (max-width: 990px) {
    left: auto;
    right: 0;
  }

  & > li {
    & > a {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 16px;
      width: 100%;
      height: 45px;
      line-height: 45px;
      color: var(--cl-secondary);
      font-size: 14px;
      font-weight: 500;
      text-decoration: none;
      // text-transform: uppercase;
      transition: all 0.2s ease-in-out;

      &:hover {
        background: #dfe5ec;
      }

      & > .group {
        display: flex;
        align-items: center;

        & > .icon {
          margin-right: 6px;
          object-fit: contain;
        }
      }

      & > .next {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.App {
  &-layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: var(--cl-light);
  }

  &-main {
    flex-grow: 1;
  }

  &-container {
    margin-top: 268px;
    &.hideBrands {
      margin-top: 194px;
    }
    @media screen and (max-width: 860px) {
      margin-top: 294px;
    }
    & .MuiContainer-root {
      &.noPadding-sm {
        @media screen and (max-width: 860px) {
          padding: 0 !important;
        }
      }
    }
  }
}

.AppHeader {
  &-area {
    background: var(--cl-bg) !important;
    // border-bottom: 1px solid rgba(234, 238, 243, 1);
    box-shadow: none !important;
  }

  &-container {
    padding-top: 32px;
    display: flex !important;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 860px) {
      padding-top: 16px;
    }
  }

  &-logo {
    display: inline-block;
    width: 150px;
    height: 40px;
    background: url('../../../assets/icons/icLogo.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}

/**
* APP HEADER EXTRA
*/
.AppHeaderExtra {
  &-area {
    background: var(--cl-secondary);

    @media screen and (max-width: 860px) {
      display: none;
    }
  }

  &-container {
    height: 40px;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
  }

  &-phone {
    margin-left: 5px;
    color: #fff;
    text-decoration: none;
  }

  &-menu {
    list-style-type: none;
    display: flex;

    & > li {
      margin-left: 24px;

      &:first-child {
        margin-left: 0;
      }

      & > a {
        color: #fff;
        text-decoration: none;

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            color: var(--cl-primary);
          }
        }
      }
    }
  }
}

/**
* APP MENU
*/
.AppMenu {
  &-container {
    width: 100%;
    z-index: 2;

    @media screen and (max-width: 860px) {
      margin: 0 -8px;
      width: unset;
    }
  }

  &-list {
    margin-top: 29px;
    list-style-type: none;
    width: 100%;
    background: var(--cl-bg);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px 8px 0 0;

    @media screen and (max-width: 860px) {
      margin-top: 5px;
      background: transparent;
      border-radius: 0;

      &::-webkit-scrollbar-track {
        display: none;
      }

      &::-webkit-scrollbar {
        display: none;
      }

      &::-webkit-scrollbar-thumb {
        display: none;
      }
    }

    @media screen and (max-width: 640px) {
      overflow-x: auto;
      justify-content: flex-start;
    }

    & > li {
      position: relative;
      height: 100%;
      flex-shrink: 0;

      &:hover {
        & .AppMenu-subList {
          display: block;

          @media screen and (max-width: 768px) {
            display: none;
          }
        }
      }

      & > a {
        display: inline-block;
        padding: 0 22px;
        height: 52px;
        line-height: 52px;
        color: #fff;
        font-size: 16px;
        font-weight: 600;
        text-decoration: none;
        border-bottom: 3px solid transparent;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &.isActive {
          background: var(--cl-secondary);
          border-bottom: 3px solid var(--cl-primary);
        }

        @media screen and (max-width: 860px) {
          padding: 16px 8px;
          height: auto;
          line-height: 15px;
          font-size: 12px;
        }

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            background: var(--cl-secondary);
            border-bottom: 3px solid var(--cl-primary);
          }
        }
      }
    }
  }

  &-subList {
    @include appMenuSublist;
    max-height: 300px;
    max-width: 260px;
    overflow-y: scroll;
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: #f5f5f5;
    }
    &::-webkit-scrollbar {
      width: 5px;
      background-color: #f5f5f5;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #555;
    }

    .group {
      width: 100%;
      width: calc(100% - 16px);
    }

    &__item {
      overflow: hidden;
      white-space: nowrap;
      padding-right: 12px;
      text-overflow: ellipsis;
    }
  }

  &-subListSm {
    & .MuiPaper-root {
      margin-top: -3px;
      border-radius: 0 0 8px 8px;
    }

    & .MuiList-root {
      @include appMenuSublist;
      display: block;
      position: relative;
    }
  }
}

/**
* APP TOOL ITEM
*/
.AppToolItem {
  &-container {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 30px;
    height: 40px;
    font-size: 12px;
    cursor: pointer;
  }

  &-icon {
    position: relative;

    & > span {
      &:first-child {
        width: 24px;
        height: 24px;
      }
    }
  }

  &-lb {
    @media screen and (max-width: 860px) {
      display: none;
    }
  }

  &-badge {
    font-family: 'Inter', sans-serif;
    position: absolute;
    padding: 0 3px;
    top: -6px;
    left: calc(100% - 6px);
    min-width: 16px;
    height: 16px;
    background: var(--cl-primary);
    font-size: 9px;
    line-height: 16px;
    text-align: center;
    border-radius: 8px;
  }
}

/**
* APP MENU SMARTPHONE
*/
.AppSidebar {
  &-container {
    min-width: 280px;
    min-height: 100vh;
    height: 100%;
  }

  &-header {
    display: flex;
    justify-content: flex-end;
    box-shadow: 0 2px 4px rgba(230, 230, 230, 0.5);
  }

  &-btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
      background: #e6e6e6;
    }
  }

  &-content {
    margin-top: 10px;
    padding-top: 6px;
    max-height: calc(100% - 50px);
    overflow-y: auto;
  }

  &-menu {
    margin-top: 24px;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      & a::after {
        display: none;
      }
    }

    & .AppLinks {
      &-img {
        height: 16px;
        width: 38px;
        object-fit: contain;
        margin: 0 6px;
      }

      &-name {
        margin-bottom: 5px;
        padding: 0 16px;
        color: var(--cl-gray);
        font-size: 10px;
        line-height: 12px;
        opacity: 0.5;
      }

      &-list {
        & > li {
          margin: 1px 0 0 0;

          & > a {
            position: relative;
            padding: 0 16px;
            height: 45px;
            line-height: 45px;
            color: var(--cl-secondary);
            font-size: 14px;
            font-weight: 500;
            text-decoration: none !important;

            // text-transform: uppercase;
            @media (hover: hover) and (pointer: fine) {
              &:hover {
                background: #dfe5ec;
              }
            }

            &:after {
              content: '';
              bottom: -1px;
              left: 16px;
              position: absolute;
              width: calc(100% - 32px);
              border-top: 1px solid var(--cl-light);
            }
          }
        }
      }
    }
  }
}

/**
* APP HEADER TOOLS
*/
.AppHeaderTools {
  &-container {
    width: 100%;
    z-index: 100;
  }

  &-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &-items {
    display: flex;

    & .AppToolItem {
      &-container {
        margin-left: 24px;

        &:first-child {
          margin-left: 0;
        }

        &.menu {
          display: none;
        }

        @media screen and (max-width: 860px) {
          margin-left: 16px !important;

          &.cart {
            order: 2;
          }

          &.user {
            order: 1;
          }

          &.menu {
            display: inline-flex;
            order: 3;
          }
        }
      }
    }
  }

  &-userMenu {
    & .MuiPaper {
      &-root {
        width: 140px;
        margin-top: 16px;
        filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.32));
        border-radius: 8px;
        overflow: visible;
        padding: 16px 0;

        &:before {
          content: '';
          position: absolute;
          top: -4px;
          left: 50%;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 6px 4px 6px;
          border-color: transparent transparent #fff transparent;
          transform: translateX(-50%);
        }

        @media screen and (max-width: 860px) {
          margin-top: 6px;
          padding: 0;
        }
      }
    }

    & .MuiList-root {
      padding: 16px 0;
    }

    & .MuiMenuItem-root {
      margin-top: 1px;
      padding: 12px 16px;
      font-family: 'Montserrat', sans-serif;
      font-size: 12px;
      font-weight: 600;
      color: var(--cl-secondary);
      position: relative;

      &:after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 16px;
        width: calc(100% - 32px);
        border-bottom: 1px solid var(--cl-light);
      }

      &:first-child {
        margin-top: 0;
      }
    }
  }
}

/**
* APP SEARCH
*/
.AppSearch {
  &-container {
    margin: 0 30px 0 85px;
    max-width: 666px;
    background: #fff;
    border-radius: 4px;
    flex-grow: 1;

    &.open {
      border-radius: 4px 4px 0 0;

      & input {
        border-radius: 4px 4px 0 0;
      }
    }

    &.sm {
      display: none;
      margin: 12px 0 0;
      max-width: 100%;
    }

    @media screen and (max-width: 860px) {
      &.pc {
        display: none;
      }

      &.sm {
        display: flex;
      }
    }
  }

  &-form {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
  }

  &-keyword {
    padding: 0 5px 0 16px;
    height: 40px;
    color: var(--cl-secondary);
    font-size: 14px;
    border: none;
    outline: none;
    flex-grow: 1;
    border-radius: 4px 0 0 4px;

    &::placeholder {
      color: var(--cl-gray);
    }
  }

  &-btnSearch {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    outline: none;
    border: none;
    cursor: pointer;
    margin-right: 12px;

    &.mr-16 {
      margin-right: 16px;
    }

    span {
      font-size: 20px;
      color: var(--cl-gray);
      transition: all 0.2s ease-in-out;

      &:hover {
        color: var(--cl-dark);
      }
    }
  }
}

.AppSearchSuggestions {
  &-container {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: #fff;
    border-radius: 0 0 4px 4px;
    overflow: hidden;

    &.open {
      border: 1px solid var(--cl-light-gray);
      box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.08);
    }
  }

  &-header {
    font-size: 12px;
    line-height: 15px;
    padding: 8px 12px;
    background: var(--cl-light);
    color: var(--cl-gray);
  }

  &-item {
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 12px;
    border-bottom: 1px solid var(--cl-light-gray);
    cursor: pointer;

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      background: var(--cl-light-gray);
    }

    &__left {
      display: flex;
      align-items: center;
      width: calc(100% - 90px);
    }

    &__right {
      .old-price {
        font-size: 10px;
        line-height: 12px;
        text-decoration-line: line-through;
        color: var(--cl-gray);
        text-align: right;
        margin-bottom: 8px;
      }

      .new-price {
        font-weight: bold;
        font-size: 12px;
        line-height: 15px;
        color: var(--cl-primary);
        text-align: right;
      }
    }
  }

  &-text {
    max-width: calc(100% - 56px);
    color: var(--cl-dark) !important;
    font-size: 12px;
    line-height: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-img {
    margin-right: 8px;
    display: inline-block;
    width: 48px;
    height: 48px;
    background-color: #fff;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 4px;
  }

  &-history {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    i {
      font-size: 24px;
      color: var(--cl-gray);
      opacity: 0.5;
      transition: opacity 0.2s ease;
    }

    &__left {
      display: flex;
      align-items: center;
      overflow: hidden;

      i {
        margin-right: 8px;
      }

      span {
        font-weight: bold;
        font-size: 12px;
        line-height: 15px;
        color: var(--cl-dark);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    .mn-icon-close:hover {
      opacity: 1;
    }
  }
}

/**
* FOOTER
*/
.Footer {
  &-area {
    background: #f2f2f2;
  }

  &-wrapperContainer {
    background: url('../../../assets/images/imgBg.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  &-mainContainer {
    padding: 72px 0 40px;
    background: var(--cl-bg);
    color: #fff;

    @media screen and (max-width: 860px) {
      padding: 25px 0 20px;
      background: transparent;
    }
  }

  &-container {
    display: flex !important;
    justify-content: space-between;
  }

  &-company {
    max-width: 275px;
    display: flex;
    flex-direction: column;
    color: var(--cl-light-gray);
    font-size: 12px;
    line-height: 15px;

    &_desc {
      margin-top: 12px;

      @media screen and (max-width: 860px) {
        display: none;
      }
    }
    .introduce {
      margin-top: 10px;
      img {
        max-width: 100%;
      }
      table, th, td {
        border: 1px solid white;
        border-collapse: collapse;
      }
    }
  }

  &-fork {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-grow: 1;
    max-width: calc(100% - 400px);

    & .AppLinks {
      &-container {
        width: 25%;
        @media screen and (max-width: 1100px) {
          margin-top: 30px;
          width: 50%;

          &:first-child,
          &:nth-child(2) {
            margin-top: 0;
          }
        }

        @media screen and (max-width: 860px) {
          display: none;
        }
      }
    }
  }

  &-copyright {
    background: var(--cl-secondary);
    color: #fff;
    font-size: 12px;
    position: relative;

    @media screen and (max-width: 860px) {
      background: transparent;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 20px;
        width: calc(100% - 40px);
        border-top: 1px solid var(--cl-gray);
      }
    }

    &_container {
      height: 40px;
      display: flex !important;
      align-items: center;

      @media screen and (max-width: 860px) {
        padding: 16px 0 24px;
        height: auto;
        justify-content: center;
      }
    }
  }

  &-phone {
    position: fixed;
    left: 24px;
    bottom: 80px;
    padding: 0 14px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    background: linear-gradient(180deg, #c40017 0%, #e1011b 100%);
    color: #fff;
    font-weight: 600;
    text-decoration: none;
    border-radius: 30px;
    box-shadow: 0px -10px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease-in-out;
    z-index: 99;
    width: 48px;

    .Ic-phone {
      min-width: 20px;
      width: 20px;
      height: 20px;
    }

    &:hover {
      width: 152px;

      & > span {
        &:last-child {
          margin-left: 8px;
          width: 96px;
          color: white;
        }
      }
    }

    & > span {
      &:last-child {
        margin-left: 0px;
        width: 0;
        overflow: hidden;
        transition: all 0.2s ease-in-out;
      }
    }
  }
  &-share {
    position: fixed;
    left: 24px;
    bottom: 24px;
    padding: 0 14px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    background: #e8ecf1 !important;
    color: #6e7171;
    font-weight: 600;
    text-decoration: none;
    border-radius: 30px;
    box-shadow: 0px -10px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease-in-out;
    z-index: 99;
    width: 48px;

    .icon {
      font-size: 18px;
      color: var(--cl-primary);
    }

    .img-share {
      width: 24px;
      height: 24px;
      border-radius: 100% !important;
      margin: 0 8px;
      cursor: pointer;
    }

    &:hover {
      width: 166px;

      & > div {
        margin-left: 8px;
        width: 110px;
        color: white;
      }
    }

    & > div {
      margin-left: 0px;
      width: 0;
      overflow: hidden;
      transition: all 0.2s ease-in-out;
    }
  }

  &-goToTop.MuiButton-root {
    position: fixed !important;
    right: 24px;
    bottom: 24px;
    border-radius: 100px !important;
    background-color: white !important;
    min-width: 48px;
    width: 48px;
    height: 48px;
    font-size: 24px;
    color: #6e7171;
    z-index: 2;
    box-shadow: 0 1px 8px rgba(153, 153, 153, 0.5);

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        color: var(--cl-primary);
      }
    }

    @media screen and(max-width: 860px) {
      right: 16px;
      // bottom: 16px;
    }
  }
}

/**
* FOOTER GO TOP
*/
.FooterGoTop {
  &-container {
    display: flex;
    justify-content: center;
    height: 40px;
    background: #3e3c46;
  }

  &-main {
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    & > span {
      color: var(--cl-gray);
      font-weight: bold;
      text-transform: uppercase;

      &:first-child {
        margin-right: 10px;
      }
    }
  }
}

.zalo-chat-widget {
  bottom: 20px !important;
  right: 82px !important;

  iframe {
    .za-chat .za-chat__head-box .logo {
      img {
        width: 48px !important;
        height: 48px !important;
        border-radius: 100% !important;
      }
    }
  }
}
.za-chat {
  .za-chat__head-box {
    .logo {
      img {
        width: 48px !important;
        height: 48px !important;
        border-radius: 100% !important;
      }
    }
  }
}

/**
* APP LINKS
*/
.AppLinks {
  &-container {
    &.contact {
      display: none;

      @media screen and (max-width: 860px) {
        display: flex;

        & > ul {
          flex-direction: row;
          align-items: center;

          & > li {
            margin: 0 16px 0 0;

            &:last-child {
              margin-right: 0;
            }

            & span {
              &.icon {
                margin: 0;
              }

              &.text {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  &-name {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
  }

  &-list {
    list-style-type: none;
    display: flex;
    flex-direction: column;

    & > li {
      margin-top: 20px;

      & > a {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        color: #fff;
        font-size: 12px;
        cursor: pointer;
        text-decoration: none;
        overflow: hidden;

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            color: var(--cl-primary);
          }
        }

        & .main {
          flex-grow: 1;
          display: flex;
          align-items: center;
          width: calc(100% - 30px);

          .text {
            white-space: nowrap;
            overflow: hidden;
            padding-right: 8px;
            text-overflow: ellipsis;
          }
        }

        & .icon {
          margin-right: 8px;
        }
      }
    }
  }
}

.Admin {
  &-layout {
    min-height: 100vh;
    display: flex;
    color: var(--cl-admin-secondary);
    background-color: #f5f7fb;

    a {
      color: var(--cl-admin-primary);
      transition: all 0.3s ease;

      &:hover {
        color: #03a9f4 !important;
      }
    }

    h2 {
      line-height: 28px;
    }

    @media screen and (max-width: 860px) {
      h2 {
        font-size: 16px;
      }
    }

    .MuiTypography-root.MuiFormControlLabel-label {
      font-size: 14px;
    }

    .MuiRadio-root.MuiRadio-colorPrimary.MuiButtonBase-root.Mui-disabled.Mui-checked {
      color: var(--cl-admin-primary);
      opacity: 0.5;
    }

    .MuiCheckbox-root.MuiCheckbox-colorPrimary.MuiButtonBase-root.Mui-disabled.Mui-checked {
      color: var(--cl-admin-primary);
      opacity: 0.5;
    }
  }

  &-main {
    flex-grow: 1;
    width: calc(100% - 24px);
    overflow-x: auto;
  }

  &-container {
    padding: 65px 40px 20px;

    @media screen and (max-width: 600px) {
      padding: 40px 16px 20px;
    }
  }
}

.MuiFormControl-root {
  .MuiInputBase-sizeSmall.MuiInputBase-formControl:not(.MuiInputBase-multiline),
  .MuiInputLabel-sizeSmall.MuiInputLabel-formControl {
    font-size: 13.5px;
    height: 36px;
    border-radius: 6px;
    font-weight: 500;
  }
}
