.body {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  .header {
    display: flex;
    justify-content: space-between;
  }
}

.footer {
  position: fixed;
  bottom: 30px;
  right: 30px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  .main_logo {
    height: 36px;
    margin-bottom: -3px;
  }

  .sub_logo {
    height: 36px;
  }
  p {
    margin-left: 5px;
    font-size: 12px;
    font-weight: 700;
  }
}
