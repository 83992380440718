.searchProducts {
  margin-bottom: 20px;
}
.noItems {
  height: 100px;
  display: flex;
  align-items: center;
  text-align: center;
}
.label-select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .product {
    &-content {
      display: flex !important;
      align-items: center;
    }
    &-img {
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      width: 80px;
      height: 80px;
      max-width: 80px;
      max-height: 80px;
    }
    &-name {
      margin-left: 15px;
      font-weight: 600;
    }
    &-price {
      margin-right: 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .newPrice {
        color: var(--cl-primary);
        font-weight: 600;
      }
    }
  }
}
.total {
  width: 300px;
  p {
    font-weight: 700;
  }
  .unit-total {
    padding-bottom: 15px;
    margin-top: 5px;
    border-bottom: 0.5px solid var(--cl-light-gray);
  }
  .discount {
    padding-top: 5px;
  }
  .total {
    padding-top: 15px;
    p {
      color: var(--cl-primary);
    }
  }
}

.inputDiscount {
  display: flex;
  width: 100%;
  margin-right: 30px;
  margin-top: 20px;
  .text {
    width: 150px;
    padding-top: 8px;
  }
}
