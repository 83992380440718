.Checkbox {
  font-size: 16px;
  cursor: pointer;
  position: relative;
  color: black;
  font-weight: bold !important;

  &.active {
    color: var(--cl-primary);
  }

  &:hover {
    opacity: 0.8;
  }

  &.disabled {
    opacity: 0.5;
    cursor: default;
  }

  i {
    font-size: 16px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: white;
  }

  &.secondary {
    color: var(--cl-gray);

    &.active {
      color: var(--cl-gray);
    }
  }
}
