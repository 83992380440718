.Home {
  &-area {
    background: transparent;
  }
  &-smallTitle {
    color: var(--cl-gray);
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
    text-align: center;
    @media screen and (max-width: 860px) {
      font-size: 12px;
      line-height: 15px;
    }
  }
  &-title {
    color: var(--cl-secondary);
    font-size: 32px;
    line-height: 39px;
    text-align: center;
    @media screen and (max-width: 860px) {
      font-size: 24px;
      line-height: 29px;
    }
  }
}
