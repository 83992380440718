@import './color';
@import './fonts';
@import './mixins';
@import './icons';
@import './custom';
@import './align';
@import '@fortawesome/fontawesome-free/css/all.min.css';
@import 'slick-carousel/slick/slick.css';
@import 'slick-carousel/slick/slick-theme.css';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

body {
  font-size: 14px;
  background: var(--cl-light);
  &.ReactModal__Body--open {
    overflow: hidden;
    &:not(.mobile) {
      padding-right: 17px;
      & .MuiAppBar-root.MuiAppBar-positionFixed {
        padding-right: 17px;
      }
    }
  }
  &.dialog {
    overflow: hidden;
  }
}

.MuiContainer {
  &-root {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
}
