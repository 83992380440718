@mixin bgIcon($url) {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url($url);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

@mixin bgIconBrand($url) {
  @include bgIcon($url);
  width: 40px;
}

@mixin imgImport($url) {
  @include bgIcon($url);
}
