.AuthLayout {
  background-color: var(--cl-light);

  &-header {
    height: 63px;
    background: #fff;
    border-bottom: 2px solid var(--cl-light-gray);

    .container {
      padding: 12px 0;
      max-width: 500px;
    }

    &__mayno {
      font-size: 10px;
      line-height: 12px;
      display: flex;
      align-items: center;
      color: var(--cl-gray);
      margin-bottom: 8px;
    }

    &__title {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      display: flex;
      align-items: center;
      color: var(--cl-secondary);
    }
  }

  &-body {
    padding: 16px 16px 32px 16px;
    max-width: 500px !important;

    &__content {
      background-color: #fff;
      padding: 16px;
    }
  }
}
