/**
* SLIDER
*/
.HomeSlider {
  &-container {
    & .slick {
      &-dots {
        bottom: 0;
        & li {
          margin: 0 6px;
          padding: 0;
          &.slick-active {
            & button:before {
              background: var(--cl-primary);
            }
          }
          & button {
            padding: 0;
            width: 24px;
            height: 4px;
            position: relative;
            &:before {
              content: '';
              width: 100%;
              height: 4px;
              background: var(--cl-gray);
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
.HomeSliderItem {
  &-container {
    height: 380px;

    @media screen and (max-width: 860px) {
      height: 180px;
    }
  }
  &-mainContainer {
    display: inline-flex !important;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    cursor: pointer;
  }
  &-content {
    text-align: center;
    max-width: 520px;
    @media screen and (max-width: 860px) {
      max-width: 270px;
    }
  }
  &-title {
    color: var(--cl-primary);
    font-size: 42px;
    line-height: 51px;
    @media screen and (max-width: 860px) {
      font-size: 24px;
      line-height: 29px;
    }
  }
  &-desc {
    margin: 16px 0 24px;
    color: #fff;
    font-size: 32px;
    line-height: 39px;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    @media screen and (max-width: 860px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
  &-btn {
    height: 40px !important;
    background: #fff !important;
    text-transform: uppercase !important;
    &:hover {
      background: var(--cl-primary) !important;
      color: #fff !important;
    }
  }
}

/**
* CATEGORIES
*/
.HomeCategories {
  &-container {
    padding: 50px 0;
    background: #f7f7f7;
    @media screen and (max-width: 860px) {
      padding: 24px 0;
    }
    & .Home-smallTitle {
      margin-bottom: 8px;
    }
  }
  &-list {
    margin-top: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  &-item {
    padding: 10px;
    width: 25%;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-right: 1px solid var(--cl-light-gray);
    transition: all 0.2s ease-in-out;
    @media screen and (max-width: 640px) {
      margin-top: 22px;
      width: 100%;
      border-right: none;
      &:first-child {
        margin-top: 0;
      }
    }
    &:last-child {
      border-right: none;
    }
    &:hover {
      transform: scale(1.1);
    }
  }
  &-img {
    display: inline-block;
    width: 100%;
    height: 90px;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    background-position: center !important;
  }
  &-txt {
    margin-top: 8px;
    color: var(--cl-secondary);
    font-size: 12px;
    line-height: 15px;
    font-weight: 600;
    // text-transform: uppercase;
    text-align: center;
  }
}

/**
* BRANDS
*/
.HomeBrands {
  &-container {
    // padding-bottom: 27px;
    background: var(--cl-light-gray);
  }
  &-list {
    // margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    .slick {
      &-list {
        width: 100%;
      }
      &-dots {
        bottom: 6px;

        & li {
          margin: 0 4px;
          padding: 0;
          height: 6px;
          width: 6px;

          &.slick-active {
            & button:before {
              background: var(--cl-primary);
            }
          }

          & button {
            padding: 0;
            width: 100%;
            height: 6px;
            position: relative;

            &:before {
              content: '';
              width: 100%;
              height: 100%;
              background: var(--cl-gray);
              opacity: 1;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
  &-image {
    width: 150px !important;
    height: 30px;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: contain;
  }
  &-item {
    width: calc(100% / 6);
    height: 75px;
    display: inline-flex !important;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    @media screen and (max-width: 1048px) {
      margin-top: 30px;
      width: calc(100% / 3);
      &:first-child,
      &:nth-child(2),
      &:nth-child(3) {
        margin-top: 0;
      }
    }
    @media screen and (max-width: 640px) {
      width: calc(50% - 10px);
      &:nth-child(3) {
        margin-top: 30px;
      }
    }
    &:hover {
      & > .icon {
        transform: scale(1.2);
      }
    }
    & .icon {
      width: 100%;
      transition: all 0.2s ease-in-out;
      @media screen and (max-width: 640px) {
        background-size: contain;
      }
    }
  }
}

/**
* BEST SELLER
*/
.HomeBestSeller {
  &-container {
    padding: 50px 0;
    background: var(--cl-light);
    &.noMore {
      padding: 50px 0 20px;
    }
    @media screen and (max-width: 860px) {
      padding: 32px 0 !important;
    }
  }
  &-list {
    margin-top: 24px;
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: 860px) {
      justify-content: space-between;
    }
  }
  &-item {
    margin: 0 0 30px 30px;
    width: calc((100% - 90px) / 4);
    height: auto !important;
    &:nth-child(4n + 1) {
      margin-left: 0;
    }
    @media screen and (max-width: 990px) {
      width: calc((100% - 60px) / 3);
      &:nth-child(4n + 1) {
        margin-left: 30px;
      }
      &:nth-child(3n + 1) {
        margin-left: 0;
      }
    }
    @media screen and (max-width: 860px) {
      margin: 12px 0 0 !important;
      width: calc(50% - 6px);
      &:first-child,
      &:nth-child(2) {
        margin-top: 0 !important;
      }
    }
  }
  &-actions {
    display: flex;
    justify-content: center;
    @media screen and (max-width: 860px) {
      margin-top: 30px;
    }
  }
  &-btn {
    min-width: 380px !important;
    max-width: 100%;
    transition: all 0.2s ease-in-out;
    @media screen and (max-width: 480px) {
      min-width: unset !important;
      width: 100%;
    }
    &:hover {
      background: var(--cl-primary) !important;
      color: #fff !important;
    }
  }
}

/**
* HOME ADVERTISEMENT
*/
.HomeAdvertisement {
  &-area {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(3, 3, 3, 0.3);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-content {
    width: 320px;
    height: 420px;
    position: relative;
    background: url('../../../../assets/images/Thumbnail1.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 8px;
    & > .icon {
      position: absolute;
      top: -10px;
      right: -10px;
      background-color: var(--cl-secondary);
      filter: brightness(2);
      border-radius: 50%;
      cursor: pointer;
    }
  }
  &-btn {
    position: absolute !important;
    bottom: 32px;
    left: 50%;
    width: 170px !important;
    height: 40px !important;
    transform: translateX(-50%);
    & > .icon {
      margin-left: 8px;
      filter: brightness(2);
    }
  }
}
