.MuiButton-root.Button {
  padding: 12px 16px;
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  text-transform: none;
  box-shadow: none;
  height: 48px;
  transition: all 0.3s ease;

  &.small {
    height: 40px;
  }

  @media screen and (max-width: 860px) {
    height: 40px;
  }

  &:hover {
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.14), 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
  }

  &:disabled {
    opacity: 0.5;
  }

  i {
    font-size: 16px;
    margin-left: 8px;
  }

  &-primary {
    background: var(--cl-primary);
    color: #fff;

    &:focus,
    &:active {
      background: var(--cl-primary);
    }

    &:hover {
      background: var(--cl-primary-80);
    }

    &:disabled {
      background: var(--cl-primary);
      color: #fff;
    }
  }

  &-secondary {
    background: var(--cl-primary-10);
    color: var(--cl-primary);

    &:focus,
    &:active {
      background: var(--cl-primary-10);
      color: var(--cl-primary);
    }

    &:hover {
      background: var(--cl-primary-80);
      color: #fff;
    }

    &:disabled {
      background: var(--cl-primary-10);
      color: var(--cl-primary);
    }
  }

  &-stroke {
    border: 1px solid var(--cl-primary);
    padding: 11px 15px;
    background-color: white;
    color: var(--cl-primary);

    &:focus,
    &:active {
      background-color: white;
      border: 1px solid var(--cl-primary);
    }

    &:hover {
      background: var(--cl-primary-80);
      color: #fff;
      border: 1px solid var(--cl-primary-80);
    }

    &:disabled {
      background-color: white;
      color: var(--cl-primary);
      border: 1px solid var(--cl-primary);
    }

    &.transparent {
      background-color: transparent;

      &:focus,
      &:active {
        background-color: transparent;
      }

      &:hover {
        background: var(--cl-primary-80);
      }

      &:disabled {
        background-color: transparent;
      }
    }
  }
}

/**
* BUTTON OPTIONS
*/
.MuiButton-root.ButtonOptions {
  &-list {
    display: flex;
    flex-wrap: wrap;
  }
  &-lb {
    display: block;
    margin-bottom: 8px;
    color: var(--cl-gray);
    font-size: 14px;
    line-height: 20px;
  }
  &-btn {
    margin: 8px 8px 4px 0;
    padding: 0 12px !important;
    height: 30px !important;
    background: var(--cl-light-gray) !important;
    color: var(--cl-dark) !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    line-height: 15px !important;
    border-radius: 30px !important;

    i {
      font-size: 12px;
    }

    &:last-child {
      margin-right: 0 !important;
    }
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background: #d0d8e2 !important;
        box-shadow: none !important;
      }
    }
    &.active {
      background: var(--cl-dark) !important;
      color: #fff !important;
    }
  }
}

@media not all and (hover: hover) {
  .MuiButton-root.Button {
    &:hover {
      box-shadow: none;
    }

    &-primary {
      &:hover {
        background: var(--cl-primary);
        color: #fff;
      }
    }

    &-secondary {
      &:hover {
        background: var(--cl-primary-10);
        color: var(--cl-primary);
      }
    }

    &-stroke {
      &:hover {
        border: 1px solid var(--cl-primary);
        padding: 11px 15px;
        background-color: white;
        color: var(--cl-primary);
      }

      &.transparent {
        &:hover {
          background-color: transparent;
        }
      }
    }
  }
}

.ButtonDrawerConfirm {
  height: 52px !important;
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 20px !important;
  letter-spacing: 0.5px;
  margin-top: 8px !important;
}
