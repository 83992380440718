.Input {
  &-label {
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: var(--cl-gray);
    margin-bottom: 4px;

    span {
      font-family: 'Inter', sans-serif !important;
    }

    &__asterisk {
      color: var(--cl-primary);
    }
  }

  &-wrapper {
    position: relative;
    text-align: left;
    display: flex;
    align-items: center;

    input,
    textarea {
      font-family: 'Inter', sans-serif !important;
      padding: 0 16px;
      color: var(--cl-secondary);
      font-size: 14px;
      height: 40px;
      border: none;
      outline: none;
      flex-grow: 1;
      border-radius: 4px;
      border: 1px solid transparent;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;

      &::placeholder {
        color: var(--cl-gray);
      }

      &:disabled {
        opacity: 0.5;
      }

      &:focus {
        outline: 0;
        box-shadow: none;
      }

      &.invalid {
        border-color: var(--cl-primary);
      }
    }

    textarea {
      height: auto;
      padding: 16px;
    }
  }

  &-border {
    input {
      border-color: var(--cl-light-gray);
    }
  }

  &-invalid {
    color: var(--cl-primary);
    text-align: left;
    margin: 4px 0;
    font-size: 12px;
    line-height: 15px;
  }

  &-icon {
    position: absolute;
    right: 16px;
    color: var(--cl-gray);
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  &-gray {
    input,
    textarea {
      background-color: var(--cl-light);
      resize: none;
    }
  }
}

.InputOTP {
  &-invalid {
    margin-top: 16px;
    text-align: center;
  }
}

.SelectPaper {
  border-radius: 2px;
  overflow-y: auto;
  scrollbar-width: thin;

  &-search {
    padding: 12px 16px;
  }

  &-paper {
    .simplebar-track {
      width: 4px !important;
      border-radius: 2px;
      right: 4px;
      background-color: var(--cl-light-gray);

      .simplebar-scrollbar {
        &::before {
          left: 0;
          right: 0;
          background-color: var(--cl-gray);
        }
      }
    }
  }

  &-buttonOptions {
    padding: 16px;

    .MuiButton-root.ButtonOptions-btn {
      margin: 12px 12px 0 0;
    }
  }
}

.SelectPaper-noItems {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: var(--cl-secondary);
  padding: 13px 16px;
}

.SelectPaper-item.MuiMenuItem-root {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: var(--cl-secondary);
  padding: 13px 16px;

  &:before {
    content: '';
    height: 1px;
    width: calc(100% - 32px);
    background-color: var(--cl-light);
    position: absolute;
    bottom: 0;
    left: 16px;
  }

  &:hover,
  &.active {
    background-color: var(--cl-light);
    color: var(--cl-primary);

    &:before {
      background-color: transparent;
    }

    @media (hover: hover) and (pointer: fine) {
      .Checkbox {
        color: black !important;

        &.active {
          color: var(--cl-primary) !important;
        }
      }
    }
  }

  &:active {
    color: var(--cl-primary-80);
  }

  .Checkbox {
    margin-right: 8px;
  }

  @media not all and (hover: hover) {
    &:hover,
    &:active {
      background-color: white;
      color: var(--cl-secondary);

      &:before {
        background-color: var(--cl-light);
      }
    }

    &.active {
      background-color: var(--cl-light);
      color: var(--cl-primary);
    }
  }
}

.SelectDrawer {
  z-index: 1500 !important;

  &-header {
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    justify-content: space-between;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: var(--cl-secondary);
    border-bottom: 1px solid var(--cl-light-gray);

    i {
      font-size: 20px;
      color: black;
      cursor: pointer;
    }
  }

  .MuiDrawer-paper {
    border-radius: 7px 7px 0 0;
  }
}

.UserInputSearch {
  height: 40px;
  position: relative;
  display: flex;
  align-items: center;

  input {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 1px solid var(--cl-light-gray);
    box-sizing: border-box;
    border-radius: 4px;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    line-height: 20px;
    padding: 10px 40px 10px 44px;
  }

  i {
    position: absolute;
    font-size: 20px;
    color: var(--cl-gray);
  }

  .mn-icon-search {
    &.adornment {
      left: 16px;
      z-index: 1;
    }

    &.button {
      right: 16px;
    }
  }

  .mn-icon-close {
    right: 16px;
  }

  .icon-cp {
    cursor: pointer;
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        color: var(--cl-dark);
      }
    }
  }

  &.isBreadcrumb {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;

    &.focus {
      width: 100%;
    }

    input {
      display: none;
      border: none;
      width: 100%;

      &.focus {
        display: block;
      }
    }
  }
}
