.AdminButton {
  &.Mui-disabled {
    &.MuiButton-containedPrimary {
      color: white;
      opacity: 0.5;
      background-color: var(--cl-admin-primary);
    }

    &.MuiButton-containedError {
      color: white;
      opacity: 0.5;
      background-color: #c62828;
    }

    &.MuiButton-containedWarning {
      color: white;
      opacity: 0.5;
      background-color: #ed6c02;
    }

    &.MuiButton-text.MuiButton-textPrimary {
      color: var(--cl-admin-primary);
      opacity: 0.5;
    }
  }

  &.MuiButton-sizeSmall {
    height: 36px;
  }
}

button.AdminButton {
  font-family: 'Inter';
  letter-spacing: 0.5px;

  &.small {
    height: 36px;
    padding: 0 12px;
    font-size: 13px;
    letter-spacing: 0.25px;

    i {
      font-size: 16px;
    }
  }
}
