.Ic {
  &-user {
    @include bgIcon('../../icons/icUser.svg');
  }
  &-shoppingCart {
    @include bgIcon('../../icons/icShoppingCart.svg');
  }
  &-search {
    @include bgIcon('../../icons/icSearch.svg');
  }
  &-backToTop {
    @include bgIcon('../../icons/icBackToTop.svg');
  }
  &-zalo {
    @include bgIcon('../../icons/icZalo.svg');
  }
  &-facebook {
    @include bgIcon('../../icons/icFacebook.svg');
  }
  &-contact {
    @include bgIcon('../../icons/icContact.svg');
  }
  &-phone {
    @include bgIcon('../../icons/icPhone.svg');
  }
  &-caterpillar {
    @include bgIconBrand('../../icons/brands/icCaterpillar.svg');
  }
  &-cummins {
    @include bgIconBrand('../../icons/brands/icCummins.svg');
  }
  &-detroit {
    @include bgIconBrand('../../icons/brands/icDetroit.svg');
  }
  &-fpDiesel {
    @include bgIconBrand('../../icons/brands/icFpDiesel.svg');
  }
  &-johnDeere {
    @include bgIconBrand('../../icons/brands/icJohnDeere.svg');
  }
  &-komatsu {
    @include bgIconBrand('../../icons/brands/icKomatsu.svg');
  }
  &-next {
    @include bgIcon('../../icons/icNext.svg');
  }
  &-close {
    @include bgIcon('../../icons/icClose.svg');
  }
  &-menu {
    @include bgIcon('../../icons/icMenu.svg');
  }
  &-checkbox {
    @include bgIcon('../../icons/icCheckbox.svg');
  }
  &-checkboxActive {
    @include bgIcon('../../icons/icCheckboxActive.svg');
  }
}
