@font-face {
  font-family: 'mn-icons';
  src:  url('fonts/mn-icons.eot?ss9mj6');
  src:  url('fonts/mn-icons.eot?ss9mj6#iefix') format('embedded-opentype'),
    url('fonts/mn-icons.ttf?ss9mj6') format('truetype'),
    url('fonts/mn-icons.woff?ss9mj6') format('woff'),
    url('fonts/mn-icons.svg?ss9mj6#mn-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="mn-icon-"], [class*=" mn-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'mn-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mn-icon-phone-call:before {
  content: "\e924";
}
.mn-icon-share:before {
  content: "\e925";
}
.mn-icon-arrow-to-top:before {
  content: "\e923";
}
.mn-icon-engine-motor:before {
  content: "\e922";
}
.mn-icon-checkbox-active-check:before {
  content: "\e905";
}
.mn-icon-checkbox-active-square:before {
  content: "\e906";
}
.mn-icon-history:before {
  content: "\e921";
}
.mn-icon-arrow-left:before {
  content: "\e920";
}
.mn-icon-alert-circle:before {
  content: "\e91e";
}
.mn-icon-check-circle:before {
  content: "\e91f";
}
.mn-icon-about:before {
  content: "\e900";
}
.mn-icon-arrow:before {
  content: "\e901";
}
.mn-icon-arrow-right:before {
  content: "\e902";
}
.mn-icon-check:before {
  content: "\e903";
}
.mn-icon-checkbox:before {
  content: "\e904";
}
.mn-icon-close:before {
  content: "\e907";
}
.mn-icon-copy:before {
  content: "\e908";
}
.mn-icon-download:before {
  content: "\e909";
}
.mn-icon-dropdown:before {
  content: "\e90a";
}
.mn-icon-edit:before {
  content: "\e90b";
}
.mn-icon-email:before {
  content: "\e90c";
}
.mn-icon-eye:before {
  content: "\e90d";
}
.mn-icon-eye-slash:before {
  content: "\e90e";
}
.mn-icon-filter:before {
  content: "\e90f";
}
.mn-icon-map:before {
  content: "\e910";
}
.mn-icon-menu:before {
  content: "\e911";
}
.mn-icon-minus:before {
  content: "\e912";
}
.mn-icon-plus:before {
  content: "\e913";
}
.mn-icon-radio:before {
  content: "\e914";
}
.mn-icon-radio-active .path1:before {
  content: "\e915";
  color: rgb(0, 0, 0);
}
.mn-icon-radio-active .path2:before {
  content: "\e916";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.mn-icon-search:before {
  content: "\e917";
}
.mn-icon-shopping-cart:before {
  content: "\e918";
}
.mn-icon-toggle-off:before {
  content: "\e919";
}
.mn-icon-toggle-on:before {
  content: "\e91a";
}
.mn-icon-user:before {
  content: "\e91b";
}
.mn-icon-vision:before {
  content: "\e91c";
}
.mn-icon-x-circle:before {
  content: "\e91d";
}
.mn-icon-file-text2:before {
  content: "\e926";
}
