.DataTable {
  font-size: 13.5px;
  font-weight: 500;

  &-header {
    padding: 0 20px 6px 20px;
    display: flex;
    align-items: center;

    .MuiOutlinedInput-input:read-only {
      cursor: pointer;
    }

    [class*='MuiOutlinedInput-root'],
    [class*='MuiInputLabel-root'] {
      font-size: 14px;
      height: 36px;
      border-radius: 6px;
      min-width: 80px;
      font-weight: 500;
    }

    .MuiButton-root {
      height: 36px;
      text-transform: none;

      i:not(.fa-upload) {
        margin-right: 8px;
      }
    }

    .space-2 {
      & > * {
        margin-right: 24px;
        margin-bottom: 12px;
      }
    }

    .space-2-left {
      & > * {
        margin-left: 18px;
        margin-bottom: 8px;
      }
    }

    .secondary {
      color: var(--cl-secondary);
    }
  }

  &-table {
    position: relative;

    th {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__sort-cell {
      display: flex;
      align-items: center;
      cursor: pointer;

      i {
        margin-left: 4px;
        visibility: hidden;
      }

      &:hover {
        i {
          visibility: visible;
          color: #777;
        }
      }

      &.active {
        i {
          visibility: visible;
          color: var(--cl-admin-secondary);
        }
      }

      &.asc {
        i {
          transform: rotateZ(180deg);
        }
      }
    }

    &.noItems {
      .MuiTableCell-root {
        border-bottom: none;
        height: 300px;
      }
      .MuiButton-root {
        height: 36px;
        text-transform: none;

        i:not(.fa-upload) {
          margin-right: 8px;
        }
      }
    }
  }

  &-loading {
    width: 100%;

    td {
      color: #777;
      border-bottom: none;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    &.has-items {
      position: absolute;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.4);
      top: 0;
      left: 0;
    }

    &.no-items {
      min-height: 200px;

      td {
        min-height: 200px;
      }
    }
  }

  .Row {
    &-avatar {
      width: 40px;
      height: 40px;
      border-radius: 20px;
    }

    &-image {
      width: 80px;
      height: 60px;
      border-radius: 8px;
    }

    &-images {
      width: 90px;
      height: 48px;

      img {
        width: 100%;
        border-radius: 4px;
        height: 100%;
      }

      &__big {
        width: 60px;
        height: 48px;
        padding: 1px 2px 1px 0;
      }

      &__small {
        width: 30px;
        height: 24px;
        padding: 1px 0;
      }
    }

    &-actions {
      display: flex;

      button {
        min-width: 28px;
        width: 28px;
        height: 28px;
        margin: 0;
      }
    }
  }

  .ChangePageSize {
    height: 25px;
    width: 80px;
    font-size: 13.5px !important;
    font-weight: 500;
  }

  .Pagination {
    position: relative;
    height: 42px;

    &-right {
      position: absolute;
      right: 18px;

      &.disabled {
        opacity: 0.5;
      }
    }

    .Mui-disabled.MuiPaginationItem-root.Mui-selected {
      color: white;
      background-color: var(--cl-admin-primary);
      opacity: 0.5;
    }
  }
  .Scrollbar {
    overflow-y: auto;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: #b5b6b700;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #c2c3c47f;
    }

    &.padding {
      padding-right: 10px;
      margin-right: -10px;
    }

    &.bold {
      scrollbar-width: unset;

      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #b5b6b76f;
      }

      &::-webkit-scrollbar-thumb:hover {
        background-color: #c2c3c4;
      }
    }

    .simplebar-track {
      width: 5px;
      border-radius: 3px;

      &.simplebar-vertical {
        width: 6px;
      }

      .simplebar-scrollbar {
        &::before {
          left: 0;
          right: 0;
          background-color: #c2c3c4df;
        }
      }
    }
  }

  .btn-refresh {
    font-size: 18px !important;
    min-width: 36px !important;
    width: 36px !important;
    padding: 0 !important;
    display: flex !important;
    justify-content: center !important;

    i {
      margin-right: 0 !important;
    }
  }
}

.ChangePageSize-item {
  height: 22px;
  font-size: 13.5px !important;
  font-weight: 500;
}

.TableSelect-item {
  height: 36px;
  font-size: 13.5px !important;
  font-weight: 500;

  &.active {
    background-color: var(--cl-admin-primary-10);
  }
}

.TableSelect-arrow {
  color: rgba(0, 0, 0, 0.54);
  margin-right: -7px;

  &.focus {
    transform: rotate(180deg);
  }
}

.TableSelect-readOnly {
  .MuiOutlinedInput-root {
    cursor: pointer !important;

    input {
      cursor: pointer !important;
    }
  }
}

.AdminPagination {
  &-button {
    min-width: 26px;
    width: 26px;
    height: 26px !important;
    border-radius: 50px;
    padding: 0;
    margin: 4px;
    font-size: 12px;
  }
}

.TableRow-clickable {
  cursor: pointer;

  &:hover {
    background-color: var(--cl-admin-secondary-10);
  }
}

.a {
  color: var(--cl-admin-primary);

  &:hover {
    color: #03a9f4;
  }
}
