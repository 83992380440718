.Pagination {
  &-container {
    display: flex;
    justify-content: flex-end;

    &.left {
      justify-content: flex-start;
    }

    .button {
      min-width: 39px;
      width: 39px;
      height: 32px;
      left: 0px;
      top: 0px;
      border-radius: 3px;
      background: white;
      border: 1px solid var(--cl-light-gray);
      margin-right: 12px;
      font-size: 12px;
      line-height: 16px;
      color: var(--cl-gray);
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      @media screen and (max-width: 374px) {
        margin-right: 10px;
        width: 33px;
        min-width: unset;
      }

      @media (hover: hover) and (pointer: fine) {
        &:hover:not(.selected) {
          border-color: var(--cl-primary);
          font-weight: bold;
          color: var(--cl-primary);
          background-color: white;
        }
      }

      &.selected {
        font-weight: bold;
        background: var(--cl-primary);
        color: white;
      }
    }
  }
}
