.Modal {
  .mn-icon-close {
    position: absolute;
    font-size: 24px;
    top: 14px;
    right: 10px;
    font-weight: 500;
    cursor: pointer;

    &:hover {
      color: var(--cl-gray);
    }
  }

  .MuiDialog-paperWidthXs {
    max-width: 359px;
  }

  .MuiDialog-paper {
    padding: 24px;
    margin: 0;
  }

  &.Modal-auth {
    .MuiDialogTitle-root {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      display: flex;
      align-items: center;
      color: var(--cl-secondary);
      padding: 0;
      margin: 0;
    }
  }

  &.Modal-user {
    .MuiDialog-paper {
      padding: 16px;
      margin: 0;
    }

    .MuiDialogTitle-root {
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      display: block;
      letter-spacing: 0.5px;
      color: var(--cl-secondary);
      margin: 0 0 8px 0;
      padding: 0;
      font-weight: bold;
    }

    .label {
      text-align: center;
    }
  }

  &.Modal-admin {
    .label {
      font-family: 'Montserrat';

      b,
      &.bold {
        font-family: 'Montserrat';
      }
    }

    .MuiDialog-paper {
      padding: 0;
      margin: 0;
    }

    .MuiDialogTitle-root {
      text-align: center;
      display: block;
    }

    &.Modal-left {
      .MuiDialogTitle-root {
        text-align: left;
      }
    }

    .MuiDialogContent-root {
      padding-top: 0px;
      max-height: calc(100vh - 200px);

      scrollbar-width: thin;

      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background-color: #b5b6b700;
      }

      &:hover::-webkit-scrollbar-thumb {
        background-color: #c2c3c47f;
      }
    }

    .MuiDialogActions-root {
      padding: 0 20px 20px 20px;

      & > * {
        margin-left: 12px;
      }
    }

    .MuiDialog-paperWidthXs {
      max-width: 414px;
    }
  }
}

.Modal-user__button {
  margin-top: 12px;
  width: 100%;
}

.ModalCancelOrder {
  &-selected {
    margin-block: 12px;
  }
  &-comment {
    margin-top: 12px;
    text-align: left;
  }

  &-label {
    margin-bottom: 4px;
  }
  &-content {
    width: 100%;
  }
  &-content:focus {
    outline: none;
  }
}

.text-hightlight {
  font-weight: 700;
  color: var(--cl-admin-primary);
}
