.about_us_content {
  .content {
    * {
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.5px;
      color: #333333;
      margin-bottom: 16px;
      font-family: 'Montserrat', sans-serif;
      word-wrap: break-word;
    }

    ol,
    ul {
      padding-left: 20px;
    }

    table,
    td,
    th {
      border: 1px solid #bbb;
    }

    td,
    th {
      padding: 6px;
    }

    table {
      width: 100%;
      border-collapse: collapse;
      margin-top: 12px;
      margin-bottom: 12px;
    }

    img {
      max-width: 100%;
    }

    figure {
      padding: 0;
      margin: 0;

      &.image {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        &.image_resized {
          margin-left: auto;
          margin-right: auto;
        }
      }

      &:not(.image) {
        margin-right: auto;
        margin-left: 0;
        text-align: left;
        &.image_resized {
          margin-right: auto;
          margin-left: 0;
        }
      }

      &.image-style-side {
        margin-left: auto;
        margin-right: 0;
        text-align: right;
        &.image_resized {
          margin-left: auto;
          margin-right: 0;
        }
      }
    }

    a {
      color: #0000dd !important;
      * {
        color: #0000dd !important;
      }
    }

    h2 {
      font-size: 23px;
      line-height: 1.3;
    }

    h3 {
      font-size: 20px;
      line-height: 1.3;
    }

    h4 {
      font-size: 17px;
    }
  }

  .video {
    width: 80%;
    margin: 0 auto;
    margin-bottom: 30px;
    .tuby-settings {
      display: none;
    }
    // button[data-tuby-tooltips='Cài đặt'] {
    //   background-color: yellow;
    //   display: none;
    // }
    .tuby-settings-dialog {
      background: #1c1c1ce6 !important;
      // display: none;
    }
    .tuby-show {
      opacity: 1 !important;
      visibility: visible !important;
    }
  }

  @media screen and (max-width: 860px) {
    .video {
      margin-top: 20px;
      width: 100%;
    }
  }
}
