.AdminSidebar {
  transition: margin-left 0.3s ease;
  position: relative;
  margin-left: -294px;
  width: 312px;
  min-width: 312px;
  color: var(--cl-admin-secondary);
  background-color: white;
  box-shadow: rgba(159, 162, 191, 0.18) 2px 0px 3px, rgba(159, 162, 191, 0.32) 1px 0px 1px;

  &-wrapper {
    position: fixed;
    width: 312px;
  }

  &-scroll {
    height: 100vh;
    padding-bottom: 30px;

    .simplebar-track {
      width: 5px !important;
      border-radius: 3px;

      .simplebar-scrollbar {
        &::before {
          left: 0;
          right: 0;
          background-color: #c2c3c4df;
        }
      }
    }
  }

  &-collapse {
    position: absolute;
    right: -14px;
    top: 56px;
    padding: 0;

    button {
      width: 26px;
      height: 26px;
      min-width: 26px;
      border-radius: 50%;
      padding: 0;
    }
  }

  &.open {
    margin-left: 0;
  }

  &.close {
    .AdminSidebar-scroll {
      overflow-y: hidden;
    }
  }

  &-avatar {
    width: 60px !important;
    height: 60px !important;
    cursor: pointer;
  }

  @media screen and (max-width: 860px) {
    position: fixed;
    z-index: 2;

    &-wrapper {
      background-color: white;
      box-shadow: rgba(159, 162, 191, 0.18) 2px 0px 3px, rgba(159, 162, 191, 0.32) 1px 0px 1px;
    }

    &-scroll {
      background-color: white;
    }

    margin-left: -300px;

    &-collapse {
      right: -36px;
    }

    &.open {
      .AdminSidebar-collapse {
        right: -14px;
      }
    }
  }
}
