.ShoppingCart {
  &-area {
    margin: 32px 0;
    @media screen and (max-width: 860px) {
      margin-top: 0;
    }
  }
  &-title {
    margin-bottom: 24px;
    color: var(--cl-secondary);
    font-size: 24px;
    font-weight: bold;
    line-height: 29px;
    @media screen and (max-width: 860px) {
      display: none;
    }
  }
}
