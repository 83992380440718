.Scrollbar {
  overflow-y: auto;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: #b5b6b700;
  }

  &:hover::-webkit-scrollbar-thumb {
    background-color: #c2c3c47f;
  }

  &.bold {
    scrollbar-width: unset;

    &::-webkit-scrollbar {
      width: 9px;
      height: 9px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #b5b6b77f;
    }

    &:hover::-webkit-scrollbar-thumb {
      background-color: #c2c3c4;
    }
  }
}
