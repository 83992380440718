.DropdownBank {
  width: 100%;
  &-title {
    color: var(--cl-gray);
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 4px;
    font-weight: 400;
  }

  &-bankingNumberWrapper {
    &.showDropDown {
      .icon {
        transform: rotate(180deg);
      }
    }
    // width: 100%;
    flex: 1;
    padding: 10px 16px;
    background: var(--cl-light);
    margin-bottom: 4px;
    position: relative;
    cursor: pointer;
    .icon {
      position: absolute;
      top: 30%;
      right: 23px;
      font-size: 24px;
      color: var(--cl-gray);
      transition: all 0.2s ease;
    }
  }
  &-contentWrapper,
  &-codeWrapper {
    width: 100%;
    padding: 16px 10px;
    background: var(--cl-light);
    margin-bottom: 4px;
  }

  &-contentWrapper {
    margin-top: 8px;
  }

  &-codeWrapper {
    margin-bottom: 24px;
  }

  &-contentLabel {
    color: var(--cl-dark);
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
  }

  &-content {
    margin-top: 8px;
    color: var(--cl-dark);
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
  }

  &-copy {
    cursor: pointer;
    color: var(--cl-primary);
    margin-top: 12px;
    i {
      font-size: 16px;
      margin-right: 8px;
    }
    span {
      color: var(--cl-primary);
    }
  }

  &-bankName {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: var(--cl-dark);

    &.mr {
      padding-right: 36px;
    }
  }
  &-codeContent {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: var(--cl-dark);
    span {
      font-weight: 700;
    }
  }
  &-widthCopy {
    width: fit-content;
    // color: var(--cl-primary);
  }

  &-attention {
    width: 443px;
    margin: 12px 0;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @media screen and (max-width: 480px) {
      width: 100%;
    }
  }
  &-attentionBorder {
    max-width: 4px;
    width: 100%;
    background: #f2994a;
    height: 24px;
    margin-right: 12px;
  }

  &-attentionContent {
    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
    color: var(--cl-gray);
    font-family: 'Inter', sans-serif !important;
    @media screen and (max-width: 480px) {
      width: 100%;
      padding: 8px 8px 8px 0px;
    }
  }

  &-listBank {
    &.showDropDown {
      display: block;
    }
    display: none;
    width: 100%;
    position: absolute;
    bottom: -100px;
    left: 0;
    list-style: none;
    background: #ffffff;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
    padding: 8px 0 12px;
    z-index: 9999;
  }
  &-itemBank {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: var(--cl-dark);
    width: 100%;
    padding: 16px 16px 13px;
    position: relative;
    cursor: pointer;

    &:before {
      content: '';
      max-width: 343px;
      height: 1px;
      width: 100%;
      background-color: #f5f7fb;
      position: absolute;
      bottom: 0;
      left: 16px;
      @media screen and (max-width: 480px) {
        max-width: calc(100% - 30px);
      }
    }

    &:hover,
    &.active {
      background-color: var(--cl-light);
      color: var(--cl-primary);

      &:before {
        background-color: transparent;
      }
    }

    &:active {
      color: var(--cl-primary-80);
    }

    @media not all and (hover: hover) {
      &:hover,
      &:active {
        background-color: white;
        color: var(--cl-secondary);

        &:before {
          background-color: var(--cl-light);
        }
      }

      &.active {
        background-color: var(--cl-light);
        color: var(--cl-primary);
      }
    }
  }
}
