@import '../../../../assets/scss/common/mixins';

/**
* EMPTY CART
*/
.EmptyCart {
  &-content {
    margin: 0 auto 32px;
    min-height: 325px;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    @media screen and (max-width: 860px) {
      margin-bottom: 0;
      min-height: 280px;
      background: transparent;
    }
    & > h5 {
      margin: 16px 0;
      font-size: 16px;
      line-height: 20px;
    }
    & > p {
      max-width: 365px;
      color: var(--cl-gray);
      font-size: 14px;
      line-height: 17px;
      & > strong {
        color: var(--cl-secondary);
      }
    }
  }
  &-icon {
    display: inline-block;
    width: 80px;
    height: 80px;
    background: url('../../../../assets/images/imgCart.png') center center no-repeat;
  }
  &-link {
    cursor: pointer;
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        color: var(--cl-primary);
      }
    }
  }
  &-recommend {
    @media screen and (max-width: 860px) {
      margin: 0 16px;
    }
  }
}

/**
* CART ITEMS
*/
.CartItems {
  &-labelWrapper {
    margin-bottom: 24px;
    @media screen and (max-width: 900px) {
      margin-bottom: 8px;
    }
  }
  &-label {
    color: var(--cl-secondary);
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    @media screen and (max-width: 900px) {
      font-size: 14px;
      font-weight: 600;
      line-height: 17px;
    }
    & > .counter {
      color: var(--cl-gray);
    }
  }
  &-table {
    background: #fff;
    & th,
    & td {
      padding: 16px 24px;
      border-bottom: 1px solid var(--cl-light-gray);
      text-align: right;
      &:first-child {
        text-align: left;
      }
    }
    & th {
      color: var(--cl-gray);
      font-size: 12px;
      line-height: 15px;
      font-weight: normal;
    }
    & tr:last-child td {
      border-bottom: none;
    }
  }
  &-addAllToCart.MuiButton-root {
    width: 154px;
    height: 28px;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    padding: 0px;
  }
}

/**
* CART ITEM
*/
.CartItem {
  &-product {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &-img {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 60px;
    height: 60px;
  }
  &-name {
    max-width: calc(100% - 78px);
    flex-grow: 1;
    & > h5 {
      color: var(--cl-secondary);
      font-size: 14px;
      font-weight: 600;
      line-height: 17px;
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      word-break: break-word;
      @media (hover: hover) and (pointer: fine) {
        &:hover {
          color: var(--cl-primary);
        }
      }
    }
    & > p {
      margin-top: 12px;
      color: var(--cl-gray);
      font-size: 12px;
      line-height: 15px;
    }
  }
  &-price {
    color: var(--cl-secondary);
    font-size: 14px;
    line-height: 17px;
    &.note {
      color: var(--cl-primary);
      font-weight: 600;
    }
  }
  &-actions {
    margin-top: 8px;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    & > button {
      margin: 5px 0 5px 10px !important;
      padding: 6px 12px !important;
      height: 28px !important;
      font-size: 10px !important;
      font-weight: 600;
      line-height: 12px !important;
      &:first-child {
        margin-left: 0 !important;
      }
    }
  }
}

/**
* CART
*/
.Cart {
  &-desc {
    color: var(--cl-dark);
    @media screen and (max-width: 860px) {
      padding: 12px 16px;
      background: #fff;
    }
  }
  &-order {
    margin-top: 24px;
    @media screen and (max-width: 860px) {
      padding: 0 16px;
    }
  }
  &-orderLater {
    margin-top: 32px;
    @media screen and (max-width: 900px) {
      margin-top: 16px;
    }
    @media screen and (max-width: 860px) {
      padding: 0 16px;
    }
  }
  &-recommend {
    margin-top: 32px;
    @media screen and (max-width: 860px) {
      margin-top: 24px;
      padding: 0 16px;
    }
  }
}

/**
* CART MONEY
*/
.CartMoney {
  &-container {
    margin-top: 24px;
    background: #fff;
    @media screen and (max-width: 900px) {
      margin-top: 8px;
      background: transparent;
    }
  }
  &-coupon {
    padding: 12px 24px;
    border-bottom: 1px solid var(--cl-light-gray);
    @media screen and (max-width: 900px) {
      padding: 16px;
      background: #fff;
      border-bottom: none;
    }
    @media screen and (max-width: 860px) {
      margin: 0 16px;
    }
  }
  &-input {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    .discount-label {
      margin-top: 12px;
      margin-right: 12px;
      line-height: 20px;
    }
    @media screen and (max-width: 480px) {
      flex-direction: column;
      align-items: flex-start;

      .discount-label {
        margin-top: 0px;
        margin-bottom: 4px;
      }

      .Input {
        width: 100%;
        &-wrapper {
          width: 100%;
        }
      }
    }
  }
  &-total {
    padding: 12px 24px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: var(--cl-gray);
    text-align: right;
    @media screen and (max-width: 900px) {
      margin-top: 16px;
      padding: 12px 16px;
      background: #fff;
    }
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }
  &-money {
    margin-right: 32px;
    @media screen and (max-width: 768px) {
      margin: 0 0 24px 0;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  &-price {
    display: inline-block;
    margin-left: 32px;
    min-width: 100px;
    color: var(--cl-secondary);
    font-weight: bold;
    @media screen and (max-width: 860px) {
      font-size: 16px;
    }
  }
  &-btn {
    &.MuiButton-root {
      min-width: 345px;
      height: 40px !important;
      @media screen and (max-width: 768px) {
        min-width: 320px;
        max-width: 100%;
      }
      @media screen and (max-width: 480px) {
        min-width: unset;
        width: 100%;
      }
    }
  }
}

/**
* CART ITEM SM
*/
.CartItemSm {
  &-container {
    margin-bottom: 8px;
    padding: 8px;
    display: flex;
    background: #fff;
    & .CartItem-img {
      margin-right: 8px;
    }
  }
  &-content {
    max-width: calc(100% - 68px);
    flex-grow: 1;
  }
  &-top {
    display: flex;
    justify-content: space-between;
    color: var(--cl-dark);
    font-size: 12px;
    line-height: 15px;
    & > span {
      &:first-child {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
      }
      &:last-child {
        margin-left: 8px;
      }
    }
  }
  &-brand {
    margin: 8px 0;
    color: var(--cl-gray);
    font-size: 10px;
    line-height: 12px;
  }
  // &-quantity {
  //   margin-bottom: 8px;
  // }
  &-actions {
    display: flex;
    justify-content: space-between;
    & > button {
      margin: 0 !important;
    }
  }
  &-inputActions {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
}
