.Questions {
  display: flex;
  flex-wrap: wrap;

  .card-noItems {
    min-height: 480px;
  }

  &Sidebar {
    width: 302px;
    padding-right: 33px;
    margin-bottom: 16px;

    &-wrapper {
      background-color: white;
    }

    &-info {
      padding: 12px 16px 28px;
      font-family: 'Inter', sans-serif;
      font-size: 16px;
      line-height: 22px;
      color: var(--cl-dark);
    }
  }

  &Container {
    width: calc(100% - 302px);
  }
}

.QuestionItem {
  background-color: white;
  border-radius: 4px;
  margin-bottom: 16px;

  &-btn {
    padding: 12px 8px !important;
    cursor: pointer;

    @media (hover: hover) and (pointer: fine) {
      &:hover,
      &:active {
        .QuestionItem-question,
        i {
          color: var(--cl-primary);
        }
      }
    }

    &__wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      i {
        font-size: 16px;
        color: var(--cl-gray);
      }
    }
  }

  &-question {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: var(--cl-secondary);
  }

  &-answer {
    padding: 0 8px 12px 8px;

    div {
      padding: 12px 16px;
      background: var(--cl-light);
      font-family: 'Inter', sans-serif;
      font-size: 16px;
      line-height: 22px;
      color: #333333;
    }
    * {
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.5px;
      color: #333333;
      margin-bottom: 16px;
      font-family: 'Montserrat', sans-serif;
      word-wrap: break-word;
    }

    ol,
    ul {
      padding-left: 20px;
    }

    table,
    td,
    th {
      border: 1px solid #bbb;
    }

    td,
    th {
      padding: 6px;
    }

    table {
      width: 100%;
      border-collapse: collapse;
      margin-top: 12px;
      margin-bottom: 12px;
    }

    img {
      max-width: 100%;
    }

    figure {
      padding: 0;
      margin: 0;

      &.image {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        &.image_resized {
          margin-left: auto;
          margin-right: auto;
        }
      }

      &:not(.image) {
        margin-right: auto;
        margin-left: 0;
        text-align: left;
        &.image_resized {
          margin-right: auto;
          margin-left: 0;
        }
      }

      &.image-style-side {
        margin-left: auto;
        margin-right: 0;
        text-align: right;
        &.image_resized {
          margin-left: auto;
          margin-right: 0;
        }
      }
    }

    a {
      color: #0000dd;
    }

    h2 {
      font-size: 23px;
      line-height: 1.3;
    }

    h3 {
      font-size: 20px;
      line-height: 1.3;
    }

    h4 {
      font-size: 17px;
    }
  }

  &-left {
    width: calc(100% - 64px);
  }
}

.QuestionsContainer-noItems {
  width: 100%;
  text-align: center;
  padding-top: 80px !important;
}

@media screen and (max-width: 860px) {
  .Questions {
    &Sidebar {
      width: 100%;
      padding-right: 0;

      &-wrapper {
        margin: 0 -16px;
      }

      &-info {
        padding: 12px 16px;
        font-size: 14px;
        line-height: 17px;
      }

      &-send {
        margin-top: 16px !important;
      }
    }

    &Container {
      width: 100%;
    }

    .card-noItems {
      padding-top: 64px;
    }
  }

  .QuestionItem {
    margin-bottom: 8px;

    &-question {
      font-size: 12px;
      line-height: 15px;
    }

    &-answer {
      padding: 0 8px 12px 8px;

      div {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .QuestionsContainer-noItems {
    button {
      width: auto !important;
    }
  }
}

.Modal-send.Modal-auth {
  .MuiDialog-paperWidthXs {
    max-width: 375px;
  }

  .MuiDialogTitle-root {
    margin-bottom: 16px;
  }
}

.SendQuestion {
  &-success {
    text-align: center;

    .card-title {
      margin-top: 16px;
      color: black;
    }

    .label {
      margin-bottom: 16px;
    }
  }
}

.SendQuestionScreen {
  .SendQuestion-success {
    img {
      margin-top: 38px;
    }
  }

  &-wrapper {
    margin-bottom: 32px;

    .SendQuestion-formSuccess {
      background-color: white;
      padding: 24px;
      margin-bottom: 32px;

      @media screen and (max-width: 860px) {
        background-color: transparent;
        padding: 0 16px 16px 16px;

        & > button {
          max-width: none !important;
          width: 100%;
        }
      }
    }

    .SendQuestion-form {
      background-color: white;
      padding: 24px;

      @media screen and (max-width: 860px) {
        padding: 16px;
      }

      .SendQuestion-label {
        margin-bottom: 28px !important;
      }
    }
  }
}
