.ProductsWrapper {
  display: flex;
  width: 100%;
  margin-top: 16px;
}

.ProductsMain {
  width: 100%;
  position: relative;

  &-no-items {
    background-color: white;
    padding: 24px;
    min-height: 504px;
    margin-bottom: 32px;

    &__wrapper {
      text-align: center;
      padding: 24px 0;
    }

    &__label {
      margin: 20px 0;
    }

    &.has-keyword {
      padding: 64px 24px 24px 24px;
      min-height: 320px;
    }
  }

  &-title {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.5px;
    margin-bottom: 20px;

    &.mb-3 {
      margin-bottom: 24px;
    }
  }

  &-body {
    width: calc(100% + 30px);
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;

    &__item {
      width: 33.33333%;
      padding: 0 15px;
      margin-bottom: 30px;

      @media screen and (max-width: 1024px) {
        width: 50%;
      }
    }

    @media screen and (max-width: 600px) {
      width: calc(100% + 12px);
      margin: 0 -6px;

      &__item {
        padding: 0 6px;
        margin-bottom: 12px;
      }
    }
  }

  &-footer {
    margin-bottom: 24px;
  }
}

.ProductsSlider {
  padding: 12px 12px 6px 12px;
  border-bottom: 1px solid #ffffff;

  &-title {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
  }

  &-value {
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    justify-content: space-between;
    color: var(--cl-dark);
  }

  &-slider {
    padding: 5px 0 !important;

    .MuiSlider-rail {
      color: var(--cl-light-gray) !important;
      opacity: 1 !important;
    }

    .MuiSlider-track {
      color: var(--cl-primary) !important;
    }

    .Mui-focusVisible,
    .Mui-active {
      box-shadow: 0px 0px 0px 8px var(--cl-primary-20) !important;
    }

    .MuiSlider-thumb {
      color: white;
      width: 8px;
      height: 8px;

      &::after {
        width: 6px;
        height: 6px;
        background-color: var(--cl-primary);
      }

      &:hover {
        box-shadow: 0px 0px 0px 8px var(--cl-primary-10) !important;
      }
    }
  }
}

.ProductsFilter {
  width: 269px;
  background-color: white;
  margin-right: 30px;
  min-width: 269px;
  color: var(--cl-secondary);
  padding: 0 16px 16px 16px;
  height: 100%;
  margin-bottom: 32px;

  &-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    padding: 16px 0;
    display: flex;
    justify-content: space-between;

    i {
      color: var(--cl-primary);
      font-size: 16px;
    }

    &__main {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    &__close {
      display: none;

      i {
        font-size: 20px;
        color: black;
        cursor: pointer;

        &:hover {
          opacity: 0.7;
        }
      }
    }
  }

  &-hr {
    border-bottom: 1px solid var(--cl-light-gray);
  }

  &-blockWrapper {
    transition: all 0.3s ease;
  }

  &-block {
    &__title {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      text-transform: uppercase;
      margin: 14px 0;
    }

    &__content {
      background-color: var(--cl-light);
    }

    &__showMore {
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      margin: 12px 0 26px;
      color: var(--cl-primary);

      span {
        cursor: pointer;
      }
    }
  }

  &-item-container {
    padding: 0 12px;
  }

  &-item {
    padding: 12px 0;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ffffff;

    &__label {
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      margin-left: 8px;
    }
  }

  &-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
    width: 237px;

    button {
      margin-left: 12px !important;
    }
  }
}

.ProductsSelect {
  margin-left: 8px;
  width: 178px;
  height: 36px;
  border-radius: 3px;
  background-color: white;
  font-size: 14px !important;
  line-height: 20px !important;
  display: flex;
  align-items: center;
  color: #333333;
  font-family: 'Inter', sans-serif !important;

  .MuiSvgIcon-root {
    width: 16px;
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid var(--cl-light-gray);
  }

  @media screen and (max-width: 480px) {
    width: 165px;
  }
}

.ProductsSelectMenu {
  .MuiPopover-paper {
    margin-top: -1px;
    background-color: white;
    border: 1px solid var(--cl-light-gray);
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
    border-radius: 0px 0px 3px 3px;

    .MuiList-padding {
      padding: 0;

      .MuiMenuItem-root {
        height: 36px;
        min-height: 36px;
        font-family: 'Inter', sans-serif !important;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: #333333;

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          width: 80%;
          height: 1px;
          background: var(--cl-light);
        }

        &.Mui-selected {
          &:after {
            background: transparent;
          }
        }
      }
    }
  }
}

.ProductsHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  align-items: center;

  .button-filter {
    display: none;
    height: 36px;
    background: #ffffff;
    border: 1px solid var(--cl-light-gray);
    border-radius: 3px;
    font-weight: 600;
    font-size: 12px;
    color: var(--cl-secondary);
    padding: 0 16px !important;
    align-items: center;
    text-transform: none;

    i {
      font-size: 16px;
      color: var(--cl-primary);
      margin-left: 4px;
    }
  }
}

@media screen and (max-width: 860px) {
  .ProductsMain {
    padding-top: 16px;

    &-no-items {
      background-color: transparent;
      min-height: auto;
      padding: 40px 24px 24px;
    }

    &-footer {
      display: flex;
      justify-content: center;
    }
  }

  .ProductsWrapper {
    margin-top: 0px;
  }

  .ProductsHeader {
    margin: 0;
    background-color: #fff;
    margin-bottom: 0px;
    padding: 16px 0;

    .count-product {
      display: none;
    }

    .button-filter {
      display: flex;
    }
  }

  .ProductsFilter {
    margin-right: 0px;
    padding-bottom: 40px;
    margin-bottom: 0;

    &-body {
      height: calc(100% - 82px);
      overflow-y: auto;
    }

    &-hr {
      margin: 0 -16px;
    }

    &-title {
      &__main {
        display: block;
        width: 100%;
      }

      &__close {
        height: 20px;
        display: block;
      }
    }
  }
}
