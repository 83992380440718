input {
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    transition: background-color 5000000s ease-in-out 0s;
  }

  &::placeholder {
    color: rgba(0, 0, 0, 0.6) !important;
    opacity: 1 !important;
  }
}

input[type='password'] {
  font: small-caption;
  font-size: 15px;
}

@media screen and (max-width: 480px) {
  input[type='password'] {
    font: small-caption;
    font-size: 12px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.rotate {
  transition: transform 0.2s ease;

  &-180 {
    transform: rotateZ(180deg);
  }
}

a {
  text-decoration: none;

  @media (hover: hover) and (pointer: fine) {
    &:hover:not(.disabled) {
      color: var(--cl-primary);
      * {
        color: var(--cl-primary);
      }
    }
  }
}

.label {
  font-size: 14px;
  line-height: 17px;
  color: var(--cl-dark);
  font-family: 'Inter', sans-serif;

  div,
  span {
    font-size: 14px;
    line-height: 17px;
    color: var(--cl-dark);
    font-family: 'Inter', sans-serif;
  }

  &.bold,
  b {
    font-family: 'Inter', sans-serif;
    font-weight: 700;
  }

  &.gray {
    color: var(--cl-gray);

    div,
    span {
      color: var(--cl-gray);
    }
  }

  &.dark-gray {
    color: #333333;
  }

  &.link {
    cursor: pointer;

    @media (hover: hover) and (pointer: fine) {
      &:hover:not(.disabled) {
        color: var(--cl-primary);
      }
    }
  }

  &.alert {
    color: var(--cl-primary);
  }

  &.disabled {
    opacity: 0.5;
    cursor: default !important;

    &:hover {
      text-decoration: none !important;
    }
  }

  &.big {
    font-size: 16px;
    line-height: 20px;
  }

  &.big-height {
    line-height: 20px;
  }

  &.secondary {
    color: var(--cl-secondary);
  }
}

.card-title {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin-bottom: 12px;

  &.hideMobile {
    color: var(--cl-secondary);

    @media screen and (max-width: 860px) {
      display: none;
    }
  }
}

.card-noItems {
  background-color: white;
  padding: 24px;
  margin-bottom: 32px;

  @media screen and (max-width: 860px) {
    background-color: transparent;
    padding: 0 16px 16px 16px;

    & > button {
      max-width: none !important;
      width: 100%;
    }
  }
}

.card-hasItems {
  background-color: white;
  padding: 24px;

  @media screen and (max-width: 860px) {
    padding: 16px;
  }
}

.form-label {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: var(--cl-secondary);

  &.mt {
    margin-top: 24px !important;
  }
}

.form-stack {
  & > *:not(:first-child):not(:last-child) {
    margin: 12px 0;
  }
}

.modal-loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--cl-primary);
}

.version {
  position: fixed;
  bottom: 20px;
  right: 160px;
  z-index: 99999;
  padding: 5px 15px;
  border-radius: 100px;
  background-color: white;
  font-weight: 500;
  box-shadow: 0 1px 8px rgba(153, 153, 153, 0.5);
  color: var(--nightblue-100);
}

.mt-12 {
  margin-top: 12px;
}

.Page-title {
  margin-top: 32px;
  margin-bottom: 16px;
  color: var(--cl-secondary);
  font-size: 24px;
  font-weight: 700;

  @media screen and (max-width: 860px) {
    display: none;
  }
}

.text-left {
  text-align: left;
}

*:focus,
*:active {
  outline: none;
}

.form-invalid {
  color: var(--cl-primary);
  text-align: left;
  margin: 4px 0;
  font-size: 12px;
  line-height: 15px;
}

.ic-s {
  font-size: 18px;
}

.ic-m {
  font-size: 22px;
}

.ic-b {
  font-size: 26px;
  font-weight: 900;
}

.mr-2 {
  margin-right: 16px;
}

.admin-link {
  cursor: pointer;
  color: var(--cl-admin-primary);
}

.label-disabled {
  opacity: 0.5;
}

.Admin-formTitle {
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 15px;
}

.FormContainer {
  width: 100%;
  max-width: 343px;

  @media screen and (max-width: 860px) {
    max-width: 100%;
  }
}

.w-100 {
  width: 100%;
}

.overflow-visible {
  overflow: visible !important;

  .MaxLine {
    overflow: visible !important;
  }
}

.AdminFormControl {
  padding: 14px 14px 6px 14px;
  font-size: 13.5px;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  min-height: 62px;
  margin-bottom: 24px;

  .MuiChip-root {
    margin: 0 8px 8px 0;
  }

  &.scroll {
    overflow: auto;

    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: #b5b6b700;
    }

    &:hover::-webkit-scrollbar-thumb {
      background-color: #c2c3c47f;
    }
  }
}
