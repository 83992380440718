.InputQuantity {
  &-lb {
    display: block;
    margin-bottom: 8px;
    color: var(--cl-gray);
    font-size: 14px;
    line-height: 20px;
  }
  &-inputWrapper {
    display: inline-flex;
    height: 40px;
    border: 1px solid var(--cl-light-gray);
    & .MuiInput-root {
      width: 56px;
      border-left: 1px solid var(--cl-light-gray);
      border-right: 1px solid var(--cl-light-gray);
      &:before,
      &:after {
        display: none;
      }
      & > input {
        padding: 0 5px;
        color: var(--cl-dark);
        font-size: 12px;
        line-height: 15px;
        text-align: center;
      }
    }
    & > button {
      width: 24px;
      background: var(--cl-light);
      color: var(--cl-dark);
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      border: none;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      @media screen and (max-width: 480px) {
        &:hover {
          background: var(--cl-light) !important;
          color: var(--cl-dark) !important;
        }
      }
      &:hover {
        background: var(--cl-gray);
        color: #fff;
      }
      &:disabled {
        cursor: default;
        background: rgba(188, 189, 194, 0.4);
        color: var(--cl-dark);
      }
    }
  }

  &-outOfStock {
    width: 106px;
    height: 40px;
    padding: 12px 16px;
    background: #e0e0e0;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: var(--cl-gray);
    opacity: 0.5;
  }
}
