/*
*EnginePartLevel1
*/
.EnginePartLevel1 {
  &-header {
    background: #ffffff;
    height: 50px;
    display: flex;
    align-items: center;
    @media screen and (max-width: 480px) {
      height: 63px;
      &--desktop {
        display: none;
      }
    }

    &--mobile {
      display: none;
      @media screen and (max-width: 480px) {
        display: block;
      }
    }
  }

  &-subTitle {
    display: none;
    margin-top: 8px;
    color: var(--cl-secondary);
    font-size: 14px;
    font-weight: 700;
    @media screen and (max-width: 480px) {
      display: block;
    }
  }

  &-title {
    margin-top: 32px;
    color: var(--cl-secondary);
    font-size: 24px;
    font-weight: 700;
    @media screen and (max-width: 480px) {
      display: none;
    }
    &--mobile {
      display: none;
      color: var(--cl-secondary);
      font-weight: 600;
      margin-top: 8px;
      @media screen and (max-width: 480px) {
        display: block;
      }
    }
  }

  &-container {
    margin-top: 24px;
    display: flex;
    flex-wrap: wrap;
    margin: 24px -15px 0;

    @media screen and (max-width: 480px) {
      margin: 16px -5px 0;
    }
  }
}

/*
*Engine Part Item Level 1
*/
.EnginePartItem {
  &-wrapper {
    width: 33.3333%;
    height: 260px;
    padding: 0 15px;
    margin-bottom: 30px;

    @media screen and (max-width: 860px) {
      width: 50%;
      height: 200px;
    }

    @media screen and (max-width: 480px) {
      padding: 0 5px;
      height: 115px;
    }
  }

  &-container {
    width: 100%;
    height: 100%;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    background-size: auto;
    overflow: hidden;
    border-radius: 3px 0px 3px 3px;

    &::before {
      z-index: 1;
      content: '';
      width: 100%;
      position: absolute;
      height: 105px;
      bottom: 0;
      left: 0;
      background: rgba(41, 39, 50, 0.5);

      @media screen and (max-width: 480px) {
        height: 56px;
      }
    }

    &__img {
      position: absolute;
      width: 120%;
      height: 120%;
      top: -10%;
      left: -10%;
      object-fit: scale-down;
    }

    .mn-icon-arrow {
      z-index: 1;
      position: absolute;
      right: 4px;
      top: 4px;
      color: #ffffff;
      font-size: 12px;
      transition: all 0.2s ease;
    }
  }

  &-background {
    position: relative;
    // background: rgba(41, 39, 50, 0.8);
    width: 100%;
    height: 100%;
    // transition: border-width 0.2s ease;
    border: 0px solid var(--cl-primary);
    z-index: 1;
  }

  &-triangle {
    width: 0;
    height: 0;
    border-bottom: 36px solid transparent;
    border-right: 36px solid #e60000;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 0.2s ease;
  }
  &-information {
    z-index: 3;
    position: absolute;
    left: 24px;
    bottom: 24px;
    @media screen and (max-width: 480px) {
      left: 12px;
      bottom: 12px;
    }
    width: calc(100% - 48px);
  }
  &-name {
    font-size: 24px;
    font-weight: 700;
    color: #ffffff;
    line-height: 29px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 6px;

    @media screen and (max-width: 480px) {
      font-size: 14px;
      line-height: 15px;
      margin-bottom: 2px;
    }
  }

  &-amount {
    text-transform: lowercase;
    font-size: 16px;
    color: #ffffff;
    line-height: 22px;
    opacity: 0.8;
    @media screen and (max-width: 480px) {
      font-size: 12px;
      line-height: 15px;
    }
  }
}

/*
*Engine Part Item Level 2
*/
.EnginePartLevel2Item {
  &-wrapper {
    width: 33.3333%;
    margin-bottom: 30px;
    cursor: pointer;
    height: 200px;
    position: relative;

    padding: 0 15px;
    margin-bottom: 30px;

    @media screen and (max-width: 860px) {
      width: 50%;
    }

    @media screen and (max-width: 480px) {
      padding: 0 5px;
      height: 115px;
    }
  }
  &-img {
    width: 100%;
    object-fit: cover;
    height: 170px;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 3px;
    @media screen and (max-width: 480px) {
      height: 75px;
    }
  }

  &-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 170px;
    border-radius: 3px;
    transition: all 0.1s ease;
    border: 0px solid var(--cl-primary);

    @media screen and (max-width: 480px) {
      height: 75px;
    }
  }

  &-name {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: var(--cl-secondary);
    margin: 8px 0px;
    @media screen and (max-width: 480px) {
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
    }
  }
}

@media (hover: hover) and (pointer: fine) {
  .EnginePartItem {
    &-wrapper:hover {
      .EnginePartItem-triangle {
        border-bottom-width: 80px;
        border-right-width: 80px;
      }
      .EnginePartItem-background {
        border-width: 8px;
      }
      .EnginePartItem-container {
        .mn-icon-arrow {
          right: 9px;
          top: 9px;
          font-size: 27px;
        }
      }
    }
  }

  .EnginePartLevel2Item {
    &-wrapper:hover {
      .EnginePartLevel2Item-overlay {
        border-width: 8px;
      }
    }
  }
}
