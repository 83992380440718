.Loading {
  &-container {
    padding: 1rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    &.full {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      // background: rgba(255, 255, 255, 0.2);
      z-index: 9999;
    }
  }
  &-dot {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: var(--cl-light-primary);
    color: var(--cl-light-primary);
    box-shadow: 9999px 0 0 0 var(--cl-light-primary);
    animation: dotFalling 1s infinite linear;
    animation-delay: 0.1s;
    &:before,
    &:after {
      content: '';
      display: inline-block;
      position: absolute;
      top: 0;
    }
    &:before {
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background-color: var(--cl-light-primary);
      color: var(--cl-light-primary);
      animation: dotFallingBefore 1s infinite linear;
      animation-delay: 0s;
    }
    &:after {
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background-color: var(--cl-light-primary);
      color: var(--cl-light-primary);
      animation: dotFallingAfter 1s infinite linear;
      animation-delay: 0.2s;
    }
  }
}

@keyframes dotFalling {
  0% {
    box-shadow: 9999px -15px 0 0 rgba(152, 128, 255, 0);
  }
  25%,
  50%,
  75% {
    box-shadow: 9999px 0 0 0 var(--cl-light-primary);
  }
  100% {
    box-shadow: 9999px 15px 0 0 rgba(152, 128, 255, 0);
  }
}

@keyframes dotFallingBefore {
  0% {
    box-shadow: 9984px -15px 0 0 rgba(152, 128, 255, 0);
  }
  25%,
  50%,
  75% {
    box-shadow: 9984px 0 0 0 var(--cl-light-primary);
  }
  100% {
    box-shadow: 9984px 15px 0 0 rgba(152, 128, 255, 0);
  }
}

@keyframes dotFallingAfter {
  0% {
    box-shadow: 10014px -15px 0 0 rgba(152, 128, 255, 0);
  }
  25%,
  50%,
  75% {
    box-shadow: 10014px 0 0 0 var(--cl-light-primary);
  }
  100% {
    box-shadow: 10014px 15px 0 0 rgba(152, 128, 255, 0);
  }
}
