.AdminBreadcrumbs {
  &-wrapper {
    flex-wrap: wrap;
  }

  i {
    margin: 0px 6px;
    display: block;
    color: var(--cl-gray);
  }

  @media screen and (max-width: 860px) {
    h2 {
      line-height: 28px;
    }
  }
}
