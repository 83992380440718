.MobileDatePicker {
  &-wrapper {
    height: 216px;

    .datepicker-content {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .datepicker-col-1 {
      margin: 0;

      &:first-child {
        li {
          justify-content: flex-end;
          padding-right: 8px;
        }
      }

      &:last-child {
        li {
          justify-content: flex-start;
          padding-left: 8px;
        }
      }
    }

    .datepicker-caption {
      display: none !important;
    }

    .datepicker.default {
      background-color: white;
      top: 0;
      bottom: none;
    }

    .datepicker.default .datepicker-scroll li {
      color: #5e5e63;

      &.disabled {
        opacity: 0.5;
      }
    }

    .datepicker-scroll > li {
      font-size: 16px !important;
      line-height: 22px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'Inter', sans-serif !important;

      &:nth-child(6) {
        color: black !important;
      }
    }

    .datepicker-wheel {
      border-top-color: #b9b9bb !important;
      border-bottom-color: #b9b9bb !important;
    }
  }

  .MuiButton-root {
    z-index: 1;
  }
}
