.AntSwitch {
  .MuiSwitch-switchBase {
    top: 2px;
    left: 2px;

    &.Mui-checked {
      .MuiSwitch-thumb {
        border: 2px solid var(--cl-primary);
      }

      + .MuiSwitch-track {
        border: 2px solid var(--cl-primary);
      }

      &.Mui-disabled {
        opacity: 0.5;
        color: transparent;

        + .MuiSwitch-track {
          opacity: 0.5;
        }
      }
    }
  }

  .MuiSwitch-thumb {
    box-shadow: none !important;
  }
}
