.ProductDetail {
  &-area {
    margin-top: 32px;
    @media screen and (max-width: 860px) {
      margin-top: 0;
    }
  }
  &-products {
    @media screen and (max-width: 860px) {
      margin-bottom: 32px;
      padding: 0 16px;
    }
  }
}
