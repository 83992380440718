:root {
  --cl-primary: #e60000;
  --cl-secondary: #030303;
  --cl-orange: #f25c05;
  --cl-dark: #272928;
  --cl-gray: #848590;
  --cl-light-gray: #e8ecf1;
  --cl-light: #f5f7fb;
  --cl-bg: #030303; //#33313c
  --cl-primary-80: #cd0000;
  --cl-primary-60: #c30101;
  --cl-primary-10: #e600001a;
  --cl-primary-20: #e6000035;
  --cl-green-2: #27ae60;
  --cl-light-primary: #ff6666;

  --cl-admin-primary: #1976d2;
  --cl-admin-primary-10: #1976d21f;
  --cl-admin-secondary: #394959;
  --cl-admin-secondary-10: #3949590a;
  --cl-admin-success: #009e0f;
}
