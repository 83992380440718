.Articles {
  display: flex;
  flex-wrap: wrap;

  .card-noItems {
    min-height: 480px;
  }

  &Sidebar {
    width: 400px;
    padding-right: 31px;
    margin-bottom: 16px;

    &-search {
      padding: 16px;
      width: 100%;
      height: 72px;
      background: #ffffff;
      margin-bottom: 24px;
    }

    &-newArticle {
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.5px;
      color: var(--cl-secondary);
      margin-bottom: 12px;
    }
  }

  &Container {
    width: calc(100% - 400px);
  }
}

.ArticleItem {
  display: flex;
  background-color: white;
  padding: 24px;
  border-radius: 4px;
  justify-content: space-between;
  margin-bottom: 16px;

  &-img {
    width: 240px;
    height: 160px;
    border-radius: 4px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &-right {
    width: calc(100% - 256px);
  }

  &-type {
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    color: var(--cl-gray);
    margin: 4px 0px;
  }

  &-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: var(--cl-secondary);
    margin: 4px 0px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
  }

  &-content {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.5px;
    color: var(--cl-dark);
    margin: 4px 0px;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
  }

  &-date {
    font-size: 12px;
    line-height: 20px;
    color: var(--cl-gray);
    margin: 4px 0px;
    font-family: 'Inter', sans-serif;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      .ArticleItem-title {
        color: var(--cl-primary) !important;
      }
    }
  }
}

.ArticleItem.small {
  padding: 8px;
  margin-bottom: 12px;

  .ArticleItem {
    &-img {
      width: 120px;
      height: 80px;
    }

    &-right {
      width: calc(100% - 128px);
    }

    &-title {
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0;
      color: var(--cl-secondary);
      margin: 4px 0px;
    }
  }
}

.ArticleDetail {
  padding: 24px 24px 8px 24px;
  background: #ffffff;
  margin-bottom: 32px;

  &-date {
    font-size: 12px;
    line-height: 20px;
    color: var(--cl-gray);
    margin-bottom: 8px;
    font-family: 'Inter', sans-serif;
  }

  &-title {
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: var(--cl-secondary);
    margin-bottom: 16px;
    word-break: break-word;
  }

  &-content {
    * {
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.5px;
      color: #333333;
      margin-bottom: 16px;
      font-family: 'Montserrat', sans-serif;
      word-wrap: break-word;
    }

    ol,
    ul {
      padding-left: 20px;
    }

    table,
    td,
    th {
      border: 1px solid #bbb;
    }

    td,
    th {
      padding: 6px;
    }

    table {
      width: 100%;
      border-collapse: collapse;
      margin-top: 12px;
      margin-bottom: 12px;
    }

    img {
      max-width: 100%;
    }

    figure {
      padding: 0;
      margin: 0;

      &.image {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        &.image_resized {
          margin-left: auto;
          margin-right: auto;
        }
      }

      &:not(.image) {
        margin-right: auto;
        margin-left: 0;
        text-align: left;
        &.image_resized {
          margin-right: auto;
          margin-left: 0;
        }
      }

      &.image-style-side {
        margin-left: auto;
        margin-right: 0;
        text-align: right;
        &.image_resized {
          margin-left: auto;
          margin-right: 0;
        }
      }
    }

    a {
      color: #0000dd;
    }

    h2 {
      font-size: 23px;
      line-height: 1.3;
    }

    h3 {
      font-size: 20px;
      line-height: 1.3;
    }

    h4 {
      font-size: 17px;
    }
  }
  .ProductItemArticle {
    &-container {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      background-color: var(--cl-light);
      padding: 12px;
      &:first-child {
        margin-left: -6px;
      }
      &:last-child {
        margin-right: -6px;
      }

      @media screen and (max-width: 1100px) {
      }
    }

    &-item {
      width: calc(100% / 4 - 12px);

      @media screen and (max-width: 1100px) {
        width: calc(100% / 2 - 12px);
        &:nth-child(1),
        &:nth-child(2) {
          margin-bottom: 12px;
        }
      }

      @media screen and (max-width: 860px) {
        width: calc(100% / 4 - 12px);
      }

      @media screen and (max-width: 720px) {
        width: calc(100% / 2 - 12px);
        &:nth-child(1),
        &:nth-child(2) {
          margin-bottom: 12px;
        }
      }
      position: relative;
      margin: 0 6px 0 6px;
      background: #fff;
      padding: 16px;
      cursor: pointer;
      border-radius: 4px;
      transition: background 0.2s ease-in-out;
      height: 100%;

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          background: #e6e6e6;
        }
      }
    }
    &-content {
      margin: 0;
    }
    &-img {
      display: inline-block;
      width: 100%;
      height: 166px;
      background-color: #fff;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      border-radius: 4px;
      margin-bottom: 0;
      @media screen and (max-width: 480px) {
        height: 166px;
      }
    }
    &-name {
      margin: 8px 0 4px;
      height: 34px;
      color: var(--cl-secondary);
      font-size: 13px;
      font-weight: 500;
      line-height: 17px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
    }
    &-prices {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin: 0;
      & > .oldPrice {
        display: block;
        width: 100%;
        margin-right: 8px;
        color: var(--cl-gray);
        min-height: 15px;
        font-size: 12px;
        line-height: 15px;
        text-decoration: line-through;
        margin-bottom: 0;
        @media screen and (max-width: 480px) {
          margin-bottom: 3px;
        }
      }
      & .currentPrice {
        color: var(--cl-primary);
        font-size: 14px;
        line-height: 17px;
        font-weight: 600;
        margin-bottom: 0;
      }
    }
    &-btn {
      background: var(--cl-primary);
      outline: none;
      border: none;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      width: 100%;
      height: 28px;
      border-radius: 3px;
      margin-bottom: 0;
      margin-top: 5px;
      z-index: 9999999;
      span {
        color: #fff;
        margin: 0;
        font-size: 12px;
      }
      .icon {
        color: #fff;
        margin-left: 10px;
        -webkit-filter: brightness(2);
        filter: brightness(2);
      }
      @media (hover: hover) and (pointer: fine) {
        &:hover {
          cursor: pointer;
          background: var(--cl-primary-80);
        }
      }
    }
    &-tag {
      padding: 4px 8px;
      position: absolute;
      top: 8px;
      left: 8px;
      color: #fff;
      font-weight: bold;
      font-size: 8px;
      line-height: 10px;
      align-items: center;
      text-transform: uppercase;
      border-radius: 2px;
      &.new {
        background: #66bbf2;
      }
      &.hot {
        background: var(--cl-primary);
      }
      &.sale {
        background: var(--cl-orange);
      }
    }
  }
}

.ArticlesContainer-noItems {
  width: 100%;
  text-align: center;
  padding-top: 80px !important;
}

@media screen and (max-width: 860px) {
  .Articles {
    &Sidebar {
      display: none;
    }

    &Container {
      width: 100%;
    }

    .card-noItems {
      padding-top: 64px;
    }
  }

  .ArticleItemsWrapper {
    padding-top: 16px;
  }

  .ArticleDetail {
    padding: 16px;
    margin-bottom: 0px;

    &-title {
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.5px;
    }

    &-content {
      * {
        font-size: 14px;
        line-height: 20px;
      }

      h2 {
        font-size: 19px;
      }

      h3 {
        font-size: 17px;
      }

      h4 {
        font-size: 15px;
      }
    }
  }

  .ArticlesContainer-noItems {
    button {
      width: auto !important;
    }
  }
}
