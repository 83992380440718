.AdminEditor {
  .ck.ck-content ul,
  .ck.ck-content ul li {
    list-style-type: inherit !important;
  }

  .ck.ck-content ul,
  .ck.ck-content ol {
    padding-left: 12px !important;
  }
  .ck.ck-content .image {
    margin-top: 0.9em;
  }
  .ck.ck-content .image-inline {
    margin-top: 0.9em;
  }
  .ck.ck-content .image-style-side {
    float: none;
    margin-left: auto;
    margin-right: 0;
    text-align: right;
    &.image_resized {
      margin-left: auto;
      margin-right: 0;
    }
  }

  .ck.ck-content.ck-editor__editable {
    min-height: 62vh;
    max-height: 62vh;
    padding: 4px 18px;
    box-shadow: none !important;

    * {
      font-family: 'Inter' !important;
      line-height: 1.6 !important;
    }

    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: #b5b6b700;
    }

    &:hover::-webkit-scrollbar-thumb {
      background-color: #c2c3c47f;
    }
  }

  &.small {
    .ck.ck-content.ck-editor__editable {
      min-height: 250px;
      max-height: 250px;
    }
  }

  &.error {
    .ck.ck-content.ck-editor__editable {
      border-color: #d32f2f;
    }
  }
}
