.NavSection {
  color: var(--cl-admin-secondary);

  &-item {
    height: 56px;
    position: relative;

    &.small {
      height: 42px;
    }

    &:hover {
      background-color: rgba(145, 158, 171, 0.08) !important;
    }

    &.active,
    &.active:hover {
      background-color: #1976d21f;
      color: var(--cl-admin-primary);

      &::before {
        top: 0px;
        right: 0px;
        width: 3px;
        bottom: 0px;
        content: '';
        position: absolute;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        background-color: var(--cl-admin-primary);
      }
    }

    &.disabled {
      pointer-events: none;
    }

    &.first {
      margin-top: -8px;
    }

    i {
      font-size: 24px;
      margin-right: 12px;
      width: 28px;
      display: flex;
      justify-content: center;
      margin-left: 10px;
    }

    span {
      font-weight: 600;
      font-size: 13px;
    }
  }

  &-sub {
    padding-left: 16px;
    font-size: 13px;
    font-weight: 600;
    display: flex;
    align-items: center;

    i {
      font-size: 16px;
      margin-right: 2px !important;
    }
  }
}
