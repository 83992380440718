.PageNotFound {
  &-area {
    padding: 60px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &-img {
    max-width: 100%;
    object-fit: contain;
    @media screen and (max-width: 860px) {
      height: 280px;
    }
  }
  &-title {
    margin: 28px 0 12px;
    color: var(--cl-dark);
    font-size: 32px;
    line-height: 39px;
    text-align: center;
    @media screen and (max-width: 860px) {
      font-size: 18px;
      line-height: 20px;
    }
    @media screen and (max-width: 480px) {
      font-size: 16px;
    }
  }
  &-msg {
    color: #333;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    font-family: 'Inter', sans-serif;
    @media screen and (max-width: 860px) {
      font-size: 14px;
    }
    @media screen and (max-width: 480px) {
      font-size: 13px;
    }
  }
  &-homePath {
    font-family: 'Inter', sans-serif;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
      color: var(--cl-primary);
    }
  }
}
