.Radio.MuiRadio-colorDefault {
  & > span:nth-child(2) {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 2px solid var(--cl-dark);

    & > * {
      display: none;
    }
  }

  &.Mui-checked {
    & > span:nth-child(2) {
      border-width: 5px;
    }
  }
}
