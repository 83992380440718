/**
* SLIDER
*/
.ProductDetailSlider {
  &-container {
    width: 445px;
    position: relative;

    &__order {
      position: absolute;
      display: none;
      height: 29px;
      background: rgba(3, 3, 3, 0.2);
      border-radius: 20px;
      padding: 8px 16px 4px;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #fff;
      bottom: 16px;
      right: 16px;
      text-align: center;
      z-index: 99;
    }

    @media screen and (max-width: 1100px) {
      width: 360px;
    }
    @media screen and (max-width: 860px) {
      width: 100%;
      background: #fff;

      &__order {
        display: block;
      }
    }
  }
  &-slider {
    width: 100%;
  }
  &-img {
    margin-bottom: 12px;

    @media screen and (max-width: 860px) {
      margin-bottom: 0;
      height: 375px;
    }
    & .slick {
      &-slide {
        & * {
          width: 100%;
        }
      }
    }
    & .ProductDetailSlider-arrow {
      display: none;
      @media screen and (max-width: 860px) {
        display: inline-block;
        &.prev {
          left: 16px;
        }
        &.next {
          right: 16px;
        }
      }
    }
  }
  &-dots {
    .slide-item {
      position: relative;

      &-inside {
        border: 2px solid transparent;
        transition: all 0.2s ease-in-out;
        border-radius: 8px;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            border-color: var(--cl-primary) !important;
          }
        }

        &.selected {
          border-color: var(--cl-primary);
        }
      }
    }

    @media screen and (max-width: 860px) {
      display: none !important;
    }
    & .slick {
      &-list {
        margin: 0 auto;
        width: 360px;
        height: 48px;
        overflow: hidden;
        @media screen and (max-width: 1100px) {
          width: 240px;
        }
      }
      &-slide {
        margin-right: 12px;
        width: 48px !important;
        height: 48px;
        cursor: pointer;

        & * {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  &-item {
    display: flex;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    cursor: pointer;
    border-radius: 8px;
    &.intro {
      width: 100%;
      height: 455px;
      @media screen and (max-width: 1100px) {
        height: 375px;
      }
    }
  }
  &-arrow {
    position: absolute;
    top: 50%;
    width: 32px;
    height: 32px;
    line-height: 32px;
    background: #fff;
    color: #848687;
    text-align: center;
    border-radius: 50%;
    border: 1px solid transparent;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.2);
    transform: translateY(-50%);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    z-index: 9;
    &:hover {
      border: 1px solid #ccc;
    }
    &.prev {
      left: 0;
      & > span {
        display: inline-block;
        transform: rotate(180deg);
      }
    }
    &.next {
      right: 0;
    }
  }
  &-imgViewer {
    & .ril {
      &__toolbar {
        display: none;
        @media screen and (max-width: 860px) {
          display: flex;
        }
      }
    }
  }
}

/**
* INTRO
*/
.ProductDetailIntro {
  &-invalid {
    margin: 8px 0 0;
    @media screen and (max-width: 860px) {
      margin: 8px 0 0 16px;
    }
  }
  &-container {
    padding: 24px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    background: #fff;
    @media screen and (max-width: 860px) {
      background: transparent;
      padding: 0;
    }
  }
  &-info {
    margin-left: 30px;
    max-width: calc(100% - 475px);
    flex-grow: 1;
    @media screen and (max-width: 1100px) {
      max-width: calc(100% - 390px);
    }
    @media screen and (max-width: 860px) {
      margin: 0;
      width: 100%;
      max-width: unset;
      background: #fff;
    }
  }
  &-title {
    margin-bottom: 16px;
    color: var(--cl-secondary);
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    word-break: break-word;
    @media screen and (max-width: 860px) {
      display: none;
    }
  }
  &-short_description {
    font-weight: 400;
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    line-height: 22px;
    color: #272928;
    margin-bottom: 16px;
    @media screen and (max-width: 860px) {
      margin-bottom: 0;
      margin-top: 8px;
    }
  }
  &-price {
    @media screen and (max-width: 860px) {
      padding: 16px;
      background: var(--cl-light);
    }
    & > p {
      &:first-child {
        margin-bottom: 4px;
        color: var(--cl-gray);
        font-size: 16px;
        line-height: 20px;
      }
      &.price {
        margin-bottom: 24px;
        color: var(--cl-primary);
        font-size: 20px;
        font-weight: bold;
        line-height: 24px;
        @media screen and (max-width: 860px) {
          margin-bottom: 0;
        }
      }
    }
  }
  &-wrapperOptions {
    @media screen and (max-width: 860px) {
      margin-bottom: 16px;
      padding: 16px 16px 0;
    }
  }
  &-options {
    margin-bottom: 16px;
  }
  &-quantity {
    @media screen and (max-width: 860px) {
      padding: 0 16px;
    }
  }
  &-quantityWrapper {
    display: flex;
    align-items: flex-end;
  }
  &-availabelQuantity {
    margin: 0 0 10px 12px;
    color: var(--cl-gray);
    line-height: 20px;
    @media screen and (max-width: 860px) {
      margin-left: -4px;
    }
    &.sm {
      display: none;
      @media screen and (max-width: 860px) {
        margin: 8px 0 0 0;
        display: block;
      }
    }
  }
  &-actions {
    margin-top: 24px;
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: 860px) {
      margin-top: 16px;
      padding: 8px 0;
      justify-content: center;
      border-top: 2px solid var(--cl-light);
    }
    @media screen and (max-width: 480px) {
      padding: 8px 16px;
    }
    & .MuiButton-root {
      padding: 0 22.5px;
      height: 40px !important;
      border-radius: 0 !important;
      @media screen and (max-width: 480px) {
        padding: 0 14.5px !important;
        max-width: 50%;
      }
      & > .icon {
        margin-left: 9px;
        filter: brightness(2);
      }
    }
  }
  &-btnAdd {
    margin-right: 16px !important;
    @media screen and (max-width: 480px) {
      margin-right: 0 !important;
      max-width: 173px !important;
    }
  }
  &-btnBuyNow {
    min-width: 170px !important;
  }
  &-outOfStock {
    & > span {
      display: block;
      font-weight: 400;
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      line-height: 20px;
      color: var(--cl-secondary);
      margin-bottom: 24px;
      @media screen and (max-width: 860px) {
        padding: 0 16px;
      }
    }
  }
  &-hotline {
    @media screen and (max-width: 480px) {
      max-width: 100% !important;
    }
  }
  &-hotline-pc {
    cursor: pointer;
    background: var(--cl-primary-10);
    color: var(--cl-primary);
    width: 318px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-in-out;
    span,
    i {
      font-weight: 600;
      font-size: 12px;
    }
    i {
      margin-left: 10px;
    }
    &:hover {
      width: 406px;
      background-color: var(--cl-primary);
      color: white;

      & > span {
        &:last-child {
          margin-left: 8px;
          width: 78px;
          color: white;
        }
      }
    }

    & > span {
      &:last-child {
        margin-left: 0px;
        width: 0;
        overflow: hidden;
        transition: all 0.2s ease-in-out;
      }
    }
  }
  &-category {
    margin-bottom: 16px;

    .categoryName {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: var(--cl-dark);
    }
  }
}

/**
* INFO
*/
.ProductDetailInfo {
  &-container {
    margin: 32px 0;
    padding: 0 24px 24px;
    background: #fff;
    @media screen and (max-width: 860px) {
      margin: 0 0 16px;
    }
  }
  &-tabs {
    & .MuiTabs-indicator {
      background-color: var(--cl-primary);
    }
    & .MuiTab-root {
      margin-right: 24px;
      padding: 12px 5px;
      color: var(--cl-gray);
      font-size: 12px;
      line-height: 15px;
      font-weight: 600;
      text-transform: none;
      border-bottom: 2px solid var(--cl-light-gray);
      &:last-child {
        margin-right: 0;
      }
      &.Mui-selected,
      &:hover {
        color: var(--cl-secondary);
        border-bottom-color: var(--cl-primary);
      }
    }
  }
  &-content {
    margin-top: 16px;

    * {
      font-size: 16px;
      font-family: 'Inter', sans-serif;
      line-height: 22px;
      word-break: break-word;
    }

    ol,
    ul {
      padding-left: 20px;
    }

    table,
    td,
    th {
      border: 1px solid #bbb;
    }

    td,
    th {
      padding: 6px;
    }

    table {
      width: 100%;
      border-collapse: collapse;
      margin-top: 12px;
      margin-bottom: 12px;
    }

    img {
      max-width: 100%;
    }

    figure {
      padding: 0;
      margin: 0;

      &.image {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        &.image_resized {
          margin-left: auto;
          margin-right: auto;
        }
      }

      &:not(.image) {
        margin-right: auto;
        margin-left: 0;
        text-align: left;
        &.image_resized {
          margin-right: auto;
          margin-left: 0;
        }
      }

      &.image-style-side {
        margin-left: auto;
        margin-right: 0;
        text-align: right;
        &.image_resized {
          margin-left: auto;
          margin-right: 0;
        }
      }
    }

    a {
      color: #0000dd;
    }
    a:hover {
      color: #0000dd !important;
      text-decoration: underline;
    }

    h2 {
      font-size: 20px;
      margin-bottom: 6px;
      line-height: 1.3;
    }

    h3 {
      font-size: 17.5px;
      margin-bottom: 5px;
      line-height: 1.3;
    }

    h4 {
      font-size: 15px;
      margin-bottom: 4px;
    }
  }
  &-desc {
    color: #333;
    font-size: 16px;
    line-height: 22px;
  }
  &-updating {
    text-align: left;
  }
}

/**
* PRODUCT INFO FOR SMARTPHONE
*/
.ProductDetailInfoSm {
  &-container {
    margin-bottom: 16px;
    background: #fff;
  }
  &-btn {
    padding: 12px 16px !important;
    display: flex !important;
    justify-content: space-between !important;
    border-top: 2px solid var(--cl-light) !important;
    & > span {
      &:first-child {
        color: var(--cl-secondary);
        font-size: 12px;
        font-weight: 600;
        line-height: 15px;
      }
      &.icon {
        color: var(--cl-gray);
        font-size: 17px;
      }
    }
  }
  &-content {
    margin: 0;
    padding: 16px;

    * {
      font-size: 14px;
      line-height: 20px;
    }
  }
  &-list {
    padding: 0 !important;
  }
}

/**
* RECOMMEND PRODUCTS
*/
.ProductRecommendProducts {
  &-container {
    margin-bottom: 32px;
    @media screen and (max-width: 860px) {
      margin-bottom: 16px;
    }
  }
  &-title {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 860px) {
      margin-bottom: 8px;
    }
    & > h5 {
      color: var(--cl-secondary);
      font-size: 24px;
      line-height: 29px;
      @media screen and (max-width: 860px) {
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
      }
    }
  }
  &-list {
    display: flex;
    justify-content: flex-start;
    @media screen and (max-width: 1048px) {
      flex-wrap: wrap;
      justify-content: flex-start;
    }
    @media screen and (max-width: 640px) {
      justify-content: space-between;
    }
    & .ProductItemNew {
      &-container {
        margin-right: 30px;
        padding: 8px 8px 16px;
        width: calc((100% - 150px) / 6);
        &:last-child {
          margin-right: 0;
        }
        @media screen and (max-width: 1048px) {
          margin-top: 24px;
          width: calc((100% - 60px) / 3);
          &:first-child,
          &:nth-child(2),
          &:nth-child(3) {
            margin-top: 0;
          }
          &:nth-child(3n + 3) {
            margin-right: 0;
          }
        }
        @media screen and (max-width: 640px) {
          margin: 9px 0 0 !important;
          width: calc(50% - 4.5px);
          &:first-child,
          &:nth-child(2) {
            margin-top: 0 !important;
          }
        }
      }
      &-img {
        height: 166px;
      }
    }
  }
  &-btnMore {
    &.small {
      height: 31px !important;
      border-radius: 0 !important;
    }

    &.big {
      height: 40px !important;
      border-radius: 3px !important;
    }

    padding: 0 16px !important;
  }
}
