.icon-password {
  width: 32px;
}

.icon-search {
  width: 20px;
  height: 20px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.InputSearch {
  .MuiInputBase-adornedStart {
    width: 100%;
    padding-left: 10px !important;
  }
  .MuiOutlinedInput-root {
    max-width: 400px !important;
  }
}
