.PaymentLayout {
  &-wrapper {
    display: flex;
    align-items: flex-start;
    padding-top: 32px;
    padding-bottom: 32px;
    @media screen and (max-width: 860px) {
      padding-top: 0;
      padding-bottom: 0;
      display: block;
    }
  }

  &-leftContent {
    max-width: calc(100% - 400px);
    width: 100%;
    margin-right: 30px;
    @media screen and (max-width: 860px) {
      max-width: 100%;
      margin-right: 0;
    }
  }

  &-title {
    font-size: 24px;
    line-height: 29px;
    color: var(--cl-secondary) !important;
    font-weight: 700;
    @media screen and (max-width: 860px) {
      display: none;
    }
  }

  &-render {
    margin-top: 22px;
  }

  &-checkLogin {
    margin-top: 12px;
    margin-bottom: 14px;
    @media screen and (max-width: 860px) {
      margin-top: 0;
      margin-bottom: 0;
      background: #fff;
      padding: 16px 16px;
      .label:first-child {
        margin-top: 0;
      }
    }
  }

  &-signIn {
    font-size: 14px;
    line-height: 17px;
    color: var(--cl-dark);
    font-weight: 400;
    span {
      font-weight: 700;
      cursor: pointer;
    }
  }

  &-signUp {
    margin-top: 12px;
    font-size: 14px;
    line-height: 17px;
    color: var(--cl-dark);
    font-weight: 400;
    span {
      font-weight: 700;
      cursor: pointer;
    }
  }

  &-progressArea {
    width: 100%;
    background: #fff;
    padding: 14px 16px;
    margin-top: 14px;
    @media screen and (max-width: 860px) {
      margin-top: 2px;
      padding: 14px 16px;
    }
  }

  &-progress {
    padding: 0 !important;

    .MuiLinearProgress-root {
      width: 100%;
      border-radius: 2px;
      background: var(--cl-light);
    }
    .MuiLinearProgress-bar {
      border-radius: 2px;
      background: var(--cl-primary);
    }
  }
  &-titleProgress {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
  }
  &-titleItem {
    color: var(--cl-gray);
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    opacity: 0.3;
    &.active {
      opacity: 1;
      color: var(--cl-secondary);
    }
    &.pass {
      opacity: 1;
    }
  }
  &-next {
    margin-top: 4px;
    @media screen and (max-width: 860px) {
      margin-top: 16px;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
  &-rightContent {
    max-width: 370px;
    width: 100%;
    &__mobile {
      @media screen and (max-width: 860px) {
        // background: var(--cl-light-gray);
        padding: 16px 16px 0;
      }
    }
    @media screen and (max-width: 860px) {
      max-width: 100%;
    }
    &__title {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: var(--cl-dark);
      margin-bottom: 8px;
      span {
        margin-left: 4px;
        color: var(--cl-gray);
      }
    }
  }
}

/*
* Form Wrapper
*/
.FormWrapper {
  margin-top: 4px;
  width: 100%;
  background: #fff;
  padding: 16px;
  .method {
    background-color: var(--cl-light);
    height: 45px;
    border-radius: 4px;
    width: 100%;
    color: var(--cl-secondary);
    font-size: 14px;
    display: flex;
    align-items: center;
    padding-left: 16px;
  }

  .store-address {
    font-size: 12px;
    &.once {
      font-size: 14px;
    }
  }
}

.Discount-code {
  padding: 8px;
  background: #fff;
  display: flex;
  align-items: flex-start;
  margin-bottom: 8px;
  justify-content: space-between;
  .input-discount {
    width: 100%;
    margin-right: 10px;
    position: relative;
    i {
      position: absolute;
      top: 34px;
      right: 20px;
      color: var(--cl-gray);
      font-size: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    & .Input {
      width: 100%;
    }
  }
  & .MuiButton-root {
    height: 45px;
    min-width: 92px;
    margin-top: 23px;
  }
}

.ProductItem {
  padding: 8px;
  background: #fff;
  display: flex;
  align-items: flex-start;
  margin-bottom: 8px;
  &-img {
    width: 48px;
    height: 48px;
    margin-right: 8px;
  }
  &-information {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 12px;
  }
  &-name {
    margin: 0;
    font-size: 14px;
    line-height: 17px;
    color: var(--cl-dark);
    font-weight: 600;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
  }
  &-amount {
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: 10px;
    line-height: 12px;
    font-weight: 400;
    color: var(--cl-gray);
  }
  &-manufacturer {
    font-size: 10px;
    line-height: 12px;
    font-weight: 400;
    color: var(--cl-gray);
  }
  &-price {
    text-align: right;
  }
  &-oldPrice {
    font-size: 10px;
    line-height: 12px;
    font-weight: 400;
    color: var(--cl-gray);
    margin-bottom: 8px;
  }
  &-newPrice {
    font-size: 12px;
    line-height: 15px;
    font-weight: 700;
    color: var(--cl-dark);

    @media screen and (max-width: 860px) {
      color: var(--cl-primary);
    }
  }
}

.UnitPrice {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  margin-bottom: 1px;
  &-label {
    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
    color: var(--cl-gray);
  }
  &-price {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: var(--cl-dark);
    &.redColor {
      color: var(--cl-primary);
      font-size: 16px;
      line-height: 20px;
      font-weight: bold;
    }
  }
}

.FormPayment {
  width: 100%;

  &-title {
    color: var(--cl-secondary) !important;
    font-weight: 600;
    font-size: 14px;
  }
  // &-selectMethodPayment {
  //   margin-top: 20px;
  // }

  &-select {
    max-width: 343px;
    width: 100%;

    @media screen and (max-width: 860px) {
      max-width: 100%;
    }
  }
  .MuiFormControl-root {
    width: 100%;
  }
  &-radio {
    .MuiTypography-root {
      font-size: 12px;
      font-weight: 500;
      line-height: 15px;
      color: var(--cl-dark);
    }
  }
  &-subForm {
    width: 100%;
    padding-bottom: 8px;
  }
  &-FormControlLabelWrapper {
    padding-top: 12px;
    padding-bottom: 12px;
    &:first-child {
      border-bottom: 1px solid var(--cl-light);
    }
    .MuiFormControlLabel-root {
      margin-left: -7px;
    }
  }
  &-switcher {
    .MuiSwitch-switchBase {
      top: 2px;
      left: 2px;
    }
    &.check {
      .MuiSwitch-switchBase {
        .MuiSwitch-thumb {
          border: 2px solid var(--cl-primary);
        }
      }
      .MuiSwitch-track {
        border: 2px solid var(--cl-primary);
      }
    }
  }
  &-switch {
    padding-top: 14px;
    padding-bottom: 14px;
    .MuiTypography-root {
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      font-family: 'Inter', sans-serif;
      color: var(--cl-gray);
      &.checked {
        color: var(--cl-secondary);
      }
    }
  }

  &-Bill {
    display: flex;
    flex-wrap: wrap;
  }
  // &-BillItem {
  //   max-width: 343px;
  //   width: 100%;
  //   @media screen and (max-width: 860px) {
  //     max-width: 343px;
  //   }
  // }
  // &-BillItem:nth-child(2n + 1) {
  //   margin-right: 16px;
  //   @media screen and (max-width: 860px) {
  //     margin-right: 0;
  //   }
  // }
}

.BankingForm {
  &-title {
    color: var(--cl-gray);
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 4px;
    font-weight: 400;
  }

  &-bankingNumberWrapper {
    &.showDropDown {
      .icon {
        transform: rotate(180deg);
      }
    }
    // width: 100%;
    flex: 1;
    padding: 10px 16px;
    background: var(--cl-light);
    margin-bottom: 4px;
    position: relative;
    cursor: pointer;
    .icon {
      position: absolute;
      top: 30%;
      right: 23px;
      font-size: 24px;
      color: var(--cl-gray);
      transition: all 0.2s ease;
    }
  }
  &-contentWrapper,
  &-codeWrapper {
    width: 100%;
    padding: 16px 10px;
    background: var(--cl-light);
    margin-bottom: 4px;
  }

  &-contentWrapper {
    margin-top: 8px;
  }

  &-codeWrapper {
    margin-bottom: 24px;
  }

  &-contentLabel {
    color: var(--cl-dark);
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
  }

  &-content {
    margin-top: 8px;
    color: var(--cl-dark);
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
  }

  &-copy {
    cursor: pointer;
    color: var(--cl-primary);
    margin-top: 12px;
    i {
      font-size: 16px;
      margin-right: 8px;
    }
    span {
      color: var(--cl-primary);
    }
  }

  &-bankName {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: var(--cl-dark);

    &.mr {
      padding-right: 36px;
    }
  }
  &-codeContent {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: var(--cl-dark);
    span {
      font-weight: 700;
    }
  }
  &-widthCopy {
    width: fit-content;
    // color: var(--cl-primary);
  }

  &-attention {
    width: 443px;
    margin: 12px 0;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @media screen and (max-width: 480px) {
      width: 100%;
    }
  }
  &-attentionBorder {
    max-width: 4px;
    width: 100%;
    background: #f2994a;
    height: 24px;
    margin-right: 12px;
  }

  &-attentionContent {
    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
    color: var(--cl-gray);
    font-family: 'Inter', sans-serif !important;
    @media screen and (max-width: 480px) {
      width: 100%;
      padding: 8px 8px 8px 0px;
    }
  }

  &-QRCode {
    img {
      max-height: 118px;
      margin-left: 10px;
      height: 100%;
    }
  }

  &-listBank {
    &.showDropDown {
      display: block;
    }
    display: none;
    width: 100%;
    position: absolute;
    bottom: -76px;
    left: 0;
    list-style: none;
    background: #ffffff;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
    padding: 8px 0 12px;
    z-index: 9999;
  }
  &-itemBank {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: var(--cl-dark);
    width: 100%;
    padding: 16px 16px 13px;
    position: relative;
    cursor: pointer;

    &:before {
      content: '';
      max-width: 343px;
      height: 1px;
      width: 100%;
      background-color: #f5f7fb;
      position: absolute;
      bottom: 0;
      left: 16px;
      @media screen and (max-width: 480px) {
        max-width: calc(100% - 30px);
      }
    }

    &:hover,
    &.active {
      background-color: var(--cl-light);
      color: var(--cl-primary);

      &:before {
        background-color: transparent;
      }
    }

    &:active {
      color: var(--cl-primary-80);
    }

    @media not all and (hover: hover) {
      &:hover,
      &:active {
        background-color: white;
        color: var(--cl-secondary);

        &:before {
          background-color: var(--cl-light);
        }
      }

      &.active {
        background-color: var(--cl-light);
        color: var(--cl-primary);
      }
    }
  }
}

.formStack {
  max-width: 343px;
  width: 100%;
  &-input {
    margin-top: 12px;
  }

  @media screen and (max-width: 860px) {
    max-width: 100%;
  }
}

.FormSummaryContainer {
  background-color: white;
  padding: 12px 16px;
  margin-top: 4px;

  @media screen and (max-width: 860px) {
    &.mx {
      margin: 4px 16px 16px;
    }
  }

  &.padding-medium {
    padding: 16px;
  }

  &.padding-big {
    padding: 20px 16px;
  }

  &.rounded {
    border-radius: 4px;
  }

  &.margin-small {
    margin-top: 1px;
  }

  &.space-beetween {
    display: flex;
    justify-content: space-between;
  }

  &-header {
    display: flex;
    justify-content: space-between;

    i {
      font-size: 16px;
      color: var(--cl-gray);
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  &-inside {
    margin-top: 12px;
    padding: 12px 16px;
    background: var(--cl-light);
  }

  &-money {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: var(--cl-dark);

    &.big {
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.5px;
    }
  }
}

.PaymentSuccess {
  background-color: white;
  text-align: center;
  padding: 80px 16px;
  width: 100%;
  margin-top: 24px;

  &-container {
    max-width: 432px;
    margin: 0 auto;
  }

  &-orderSuccess {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.5px;
    color: black;
    margin: 16px 0 12px;
  }

  @media screen and (max-width: 860px) {
    background-color: transparent;
  }
}
