.Breadcrumbs {
  &-area {
    min-height: 50px;
    background: #fff;
    border-bottom: 2px solid var(--cl-light-gray);
    position: relative;
    display: flex;
    align-items: center;
  }
  &-defaultName {
    font-weight: 600 !important;
    color: var(--cl-gray) !important;
  }
  &-list {
    padding: 16px 0 8px;
    list-style-type: none;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    font-weight: 500;

    .mn-icon-arrow-right {
      font-size: 16px;
    }

    @media screen and (max-width: 860px) {
      padding: 16px 0 6px;
      font-weight: 400;

      .mn-icon-arrow-right {
        font-size: 8px;
      }
    }

    & > li {
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      position: relative;
      &:last-child {
        @media screen and (max-width: 860px) {
          width: 100%;
          & > a {
            font-size: 14px;
            line-height: 17px;
          }
        }
        & > a {
          color: var(--cl-secondary);
          font-weight: 600;
          text-decoration: none !important;
          cursor: default;
          pointer-events: none;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      & > a {
        color: var(--cl-gray);
        font-size: 14px;
        line-height: 17px;
        text-decoration: none;
        cursor: pointer;
        @media (hover: hover) and (pointer: fine) {
          &:hover {
            color: var(--cl-primary);
          }
        }
        @media screen and (max-width: 860px) {
          font-size: 10px;
          line-height: 12px;
        }
      }
      & > span {
        display: inline-block;
        margin: 0 17px;
        color: var(--cl-gray);
        @media screen and (max-width: 860px) {
          margin: 0 12px;
        }
      }

      .Breadcrumbs-status {
        position: absolute;
        right: 0;
        font-size: 12px;
        line-height: 15px;
        font-weight: 400;
        color: var(--cl-gray);
      }
    }
  }
}
