.AboutUs {
  &-label {
    color: var(--cl-secondary);
    font-size: 24px;
    line-height: 29px;
    font-weight: 700;
    margin-top: 32px;
    @media screen and (max-width: 860px) {
      display: none;
    }
  }
  &-wrapper {
    margin-top: 12px;
    @media screen and (max-width: 860px) {
      margin-top: 0;
      background: #fff;
      padding-top: 24px;
      padding-left: 16px;
      padding-right: 16px;

      .MuiBox-root {
        flex-direction: column;
        align-items: center;
        .MuiTabs-root {
          width: 100%;
        }
      }
    }
  }

  &-labelTab {
    i {
      margin-right: 9px;
      font-size: 18px;
    }
  }

  &-Tabs {
    margin-right: 30px;
    @media screen and (max-width: 860px) {
      margin-right: 0;
    }
    &-button.none {
      display: none !important;
    }
  }

  &-tabpanel {
    max-width: calc(100% - 300px);
    width: 100%;
    background: #fff;
    padding: 24px;
    margin-bottom: 32px;

    @media screen and (max-width: 860px) {
      max-width: 100%;
      padding: 0;
    }
  }
  &-tabStore {
    max-width: 100%;
    width: 100%;
    background: #fff;
    padding: 24px;
    margin-bottom: 28px;

    @media screen and (max-width: 860px) {
      max-width: 100%;
      padding: 0;
      margin-top: 24px;
    }
    .text-main {
      font-size: 16px;
      line-height: 20px;
      font-weight: 700;
      color: var(--cl-secondary);
    }
    .label {
      margin-top: 16px;
      font-size: 16px;
      line-height: 22px;
      font-weight: 400;
      color: var(--cl-dark);
    }
  }

  .MuiTabs-flexContainer {
    width: 270px;
    margin-right: 30px;
    @media screen and (max-width: 860px) {
      width: 100%;
      margin-right: 0;
      display: flex;
      flex-direction: row;
      overflow: auto;
      justify-content: flex-start;
    }
    .MuiButtonBase-root:nth-child(1) {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      border-radius: 3px;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)),
        url(../../../../assets/images/Tababoutus.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #fff;
      &:hover {
        background: var(--cl-primary);
      }
      @media screen and (max-width: 860px) {
        margin-right: 12px;
        font-weight: 600;
        &:hover {
          background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)),
            url(../../../../assets/images/Tababoutus.png);
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        }
      }

      &.Mui-selected {
        color: #fff;
        background: var(--cl-primary) !important;
        .MuiTabs-indicator {
          display: none !important;
        }
        .css-10d9dml-MuiTabs-indicator {
          display: none !important;
        }
      }
    }

    .MuiButtonBase-root:nth-child(2) {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      border-radius: 3px;
      margin-top: 12px;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2)),
        url(../../../../assets/images/TabTamNhin.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #fff;
      &:hover {
        background: var(--cl-primary);
      }
      @media screen and (max-width: 860px) {
        margin-top: 0;
        margin-right: 12px;
        font-weight: 600;
        &:hover {
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2)),
            url(../../../../assets/images/TabTamNhin.png);
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
      &.Mui-selected {
        color: #fff;
        background: var(--cl-primary) !important;
        .MuiTabs-indicator {
          display: none !important;
        }
        .css-10d9dml-MuiTabs-indicator {
          display: none !important;
        }
      }
    }
    .MuiButtonBase-root:nth-child(3) {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      border-radius: 3px;
      margin-top: 12px;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2)),
        url(../../../../assets/images/Tablocation.jpg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #fff;
      &:hover {
        background: var(--cl-primary);
      }
      @media screen and (max-width: 860px) {
        margin-top: 0;
        font-weight: 600;
        &:hover {
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2)),
            url(../../../../assets/images/Tablocation.jpg);
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
      &.Mui-selected {
        color: #fff;
        background: var(--cl-primary) !important;
        .MuiTabs-indicator {
          display: none !important;
        }
        .css-10d9dml-MuiTabs-indicator {
          display: none !important;
        }
      }
    }

    // .MuiButtonBase-root:hover {
    //   background: var(--cl-primary);
    // }

    .MuiTabs-indicator {
      display: none !important;
    }

    .css-15oadbm-MuiButtonBase-root-MuiTab-root.Mui-selected {
      color: #fff;
      background: var(--cl-primary) !important;
      .MuiTabs-indicator {
        display: none !important;
      }
      .css-10d9dml-MuiTabs-indicator {
        display: none !important;
      }
    }
  }

  &-TabAboutUS {
    font-size: 24px;
    line-height: 29px;
    font-weight: 700;
    color: var(--cl-secondary);
  }
  &-content {
    margin-top: 16px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    color: var(--cl-dark);
  }

  &-contentImage {
    width: 100%;
    margin-top: 16px;
    text-align: center;
    cursor: pointer;
    @media screen and (max-width: 860px) {
      display: none;
    }
  }

  &-caring {
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    color: var(--cl-secondary);
    margin-top: 16px;
  }

  &-contentImage__mobile {
    display: none;
    margin-block: 24px;
    width: 100%;
    text-align: center;
    cursor: pointer;
    @media screen and (max-width: 860px) {
      display: block;
    }
  }

  &-contentImageCaring {
    margin-top: 16px;
    background-size: contain;
    cursor: pointer;
    @media screen and (max-width: 860px) {
      background-size: cover;
    }
  }
}
