.ProductItemNew {
  &-container {
    padding: 16px;
    background: #fff;
    position: relative;
    cursor: pointer;
    border-radius: 4px;
    transition: background 0.2s ease-in-out;
    height: 100%;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background: #e6e6e6;
      }
    }
  }
  &-img {
    display: inline-block;
    width: 100%;
    height: 270px;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 4px;
    @media screen and (max-width: 480px) {
      height: 166px;
    }
  }
  &-name {
    margin: 8px 0 4px;
    height: 34px;
    color: var(--cl-secondary);
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
  }
  &-prices {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    & > .oldPrice {
      margin-right: 8px;
      color: var(--cl-gray);
      font-size: 12px;
      line-height: 15px;
      text-decoration: line-through;
      @media screen and (max-width: 480px) {
        margin-bottom: 3px;
      }
    }
    & .currentPrice {
      color: var(--cl-primary);
      font-size: 14px;
      line-height: 17px;
      font-weight: 600;
    }
  }
  &-tag {
    padding: 4px 8px;
    position: absolute;
    top: 8px;
    left: 8px;
    color: #fff;
    font-weight: bold;
    font-size: 8px;
    line-height: 10px;
    align-items: center;
    text-transform: uppercase;
    border-radius: 2px;
    &.new {
      background: #66bbf2;
    }
    &.hot {
      background: var(--cl-primary);
    }
    &.sale {
      background: var(--cl-orange);
    }
  }
}

@media screen and (max-width: 480px) {
  .ProductItemNew {
    &-name {
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      height: 30px;
    }
    &-prices {
      & > .oldPrice {
        font-size: 10px;
        line-height: 12px;
      }
      & .currentPrice {
        font-size: 12px;
        line-height: 15px;
        font-weight: bold;
      }
    }
  }
}

.h-auto {
  &.ProductItemNew-container {
    height: auto !important;
  }
}

.medium {
  &.ProductItemNew-container {
    height: auto !important;
  }
  .ProductItemNew {
    &-name {
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      height: 30px;
    }
    &-prices {
      & > .oldPrice {
        font-size: 10px;
        line-height: 12px;
      }
      & .currentPrice {
        font-size: 12px;
        line-height: 15px;
        font-weight: bold;
      }
    }
  }
}
